import * as React from "react";
import {Component} from "react";
import {
    PAY_INSTALLMENTS,
    PAY_NOW,
    IAddress,
    IInstallment,
    IPaymentMethod,
    IBillingAddress,
    IAddressError, 
    IBillingInstallment,
    IBank,
    IRawBankResponse
} from "../types";
import CreditCardForm from "./form/creditCardForm";
import DirectDebitForm from "./form/directDebitForm";
import PaymentMethodList from "./paymentMethodList";
import PaymentTimeList from "./paymentTimeList";
import PaymentInstallmentList from "./installments/paymentInstallmentList";
import PaymentAddressList from "./billingAddress/paymentAddressList";
import CustomForm from "./form/customForm";
// @ts-ignore
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
//@ts-ignore
import Logger from "qidigo-logger";
import qidigoAuth from "../../../modules/qidigo-auth";
// @ts-ignore
import Loading from 'qidigo-components/loading';

const translations = defineMessages({
    "choose_a_payment_method": {
        id: "qidigo.billing.payment.choose_a_payment_method",
        defaultMessage: "Choisir une méthode de paiement"
    },
    "choose_an_option": {
        id: "qidigo.billing.payment.choose_an_option",
        defaultMessage: "Choisir une option"
    },
});

interface IPaymentProps {
    paymentMethods: Array<IPaymentMethod>,
    addressList: Array<IAddress>,
    installmentList: Array<IInstallment>,
    handleTokenBadRequest: (code: string) => void,
    handlePayment: (paymentMethod: IPaymentMethod, installmentId: string|null, token: string|null) => void,
    paymentErrors: object,
    showPaymentErrorBox: boolean,
    processingPayment: boolean,
    organizationUuid: string,
    disabled: boolean,
    billingInstallments: Array<IBillingInstallment>
    selectedInstallmentId: string | null,
    selectedPaymentMethodId: number | null
}

interface IPaymentState {
    isOnlinePayment: boolean,
    isInstallment: boolean,
    selectedPaymentMethod: IPaymentMethod,
    selectedPaymentTime: number|null,
    selectedPaymentInstallment: string|null,
    selectedBillingAddress: IBillingAddress|null,
    hasBillingAddressError: boolean,
    shouldShowPaymentTimeSelection: boolean,
    banks: null|IBank[],
    defaultBank: null|IBank
}

const CUSTOM_METHODS = 'CUSTOM';
const DIRECT_DEBIT_METHOD = 'PAYSAFE_CAD_DD';
const DEFAULT_DIRECT_DEBIT_INSTITUTION_ID =  '815'

class Payment extends Component<IPaymentProps, IPaymentState> {
    constructor(props: IPaymentProps) {
        super(props);

        const immediatePaymentDefaultPaymentMethod = props.paymentMethods.filter((paymentMethod : IPaymentMethod) => {
            return this.isEligibleNowPaymentMethod(paymentMethod);
        })[0];

        const paymentTimeDefaultPaymentMethod = props.paymentMethods.filter((paymentMethod : IPaymentMethod) => {
            return this.getEligibleInstallments(paymentMethod, this.props.installmentList).length > 0;
        })[0];

        let selectedPaymentMethod = immediatePaymentDefaultPaymentMethod
            ? immediatePaymentDefaultPaymentMethod
            : paymentTimeDefaultPaymentMethod;

        selectedPaymentMethod = this.props.selectedPaymentMethodId !== null
            ? this.props.paymentMethods.find(paymentMethods => paymentMethods.id === this.props.selectedPaymentMethodId)
            || selectedPaymentMethod
            : selectedPaymentMethod;

        const paymentTimeDefault = selectedPaymentMethod
            ? this.setDefaultSelectedPaymentTime(selectedPaymentMethod)
            : null;

        let selectedPaymentTime = paymentTimeDefault === null ? PAY_NOW : paymentTimeDefault;
        selectedPaymentTime = this.props.selectedInstallmentId !== null ?  PAY_INSTALLMENTS : selectedPaymentTime;

        this.state = {
            isOnlinePayment: selectedPaymentMethod && selectedPaymentMethod.type !== CUSTOM_METHODS,
            isInstallment: (selectedPaymentMethod
                && !selectedPaymentMethod.can_pay_now)
                || selectedPaymentTime === PAY_INSTALLMENTS,
            selectedPaymentMethod: selectedPaymentMethod,
            selectedPaymentTime: selectedPaymentTime,
            selectedPaymentInstallment: this.props.selectedInstallmentId,
            selectedBillingAddress: props.addressList.length > 0
                ? this.convertToBillingAddress(props.addressList[0])
                : null,
            hasBillingAddressError: false,
            shouldShowPaymentTimeSelection: paymentTimeDefault === null,
            banks: null,
            defaultBank: null
        }
    }

    setDefaultSelectedPaymentTime = (selectedPaymentMethod : IPaymentMethod): number|null => {
        const eligibleInstallments = this.getEligibleInstallments(
            selectedPaymentMethod,
            this.props.installmentList
        );

        const hasInstallmentPaymentTime =  eligibleInstallments.length > 0;

        if (selectedPaymentMethod.can_pay_now && hasInstallmentPaymentTime) {
            return null;
        }

        if (hasInstallmentPaymentTime) {
            return PAY_INSTALLMENTS;
        }

        return PAY_NOW;

    }

    componentDidUpdate(prevProps: IPaymentProps, prevState: IPaymentState)
    {
        const eligiblePreviousPaymentMethods = prevProps.paymentMethods.filter(
            (paymentMethod) => this.isEligiblePaymentMethod(paymentMethod, prevProps.installmentList)
        );

        const nowEligiblePaymentMethods = this.props.paymentMethods.filter(
            (paymentMethod) => this.isEligiblePaymentMethod(paymentMethod, this.props.installmentList)
        );

        const hasEligiblePaymentMethods = nowEligiblePaymentMethods.length > 0;
        const previouslySelectedMethod = nowEligiblePaymentMethods.find((paymentMethod) => paymentMethod.id === prevState.selectedPaymentMethod.id);
        if (
            hasEligiblePaymentMethods
            && (eligiblePreviousPaymentMethods.length === 0 || previouslySelectedMethod === undefined)
        ) {
            const onUpdatePropsDefaultPaymentMethod = nowEligiblePaymentMethods[0];
            this.setState({
                selectedPaymentMethod: onUpdatePropsDefaultPaymentMethod,
                isOnlinePayment: onUpdatePropsDefaultPaymentMethod.type !== CUSTOM_METHODS,
                isInstallment: false,
            });
        }

        if (previouslySelectedMethod) {
            const isPaymentMethodUpdated = previouslySelectedMethod.can_pay_now !== prevState.selectedPaymentMethod.can_pay_now
                || previouslySelectedMethod.installment_ids !== prevState.selectedPaymentMethod.installment_ids;

            if (isPaymentMethodUpdated) {
                this.setState({
                    selectedPaymentMethod: previouslySelectedMethod
                });
            }
        }

        if (prevState.selectedPaymentMethod !== this.state.selectedPaymentMethod) {
            const paymentTime = this.setDefaultSelectedPaymentTime(this.state.selectedPaymentMethod);

            this.selectPaymentTime(
                paymentTime === null ? PAY_NOW : paymentTime, 
                paymentTime === null
            );
        }
    }

    async componentDidMount() {
        if (this.state.banks === null && this.hasPaysafeDirectDebitAvailable()) {
            this.fetchBankAccountTransitRestriction();
        }
    }

    hasPaysafeDirectDebitAvailable = (): boolean => {

        const {paymentMethods} = this.props;
        const directDebitPaymentMethod = paymentMethods.find((paymentMethod: IPaymentMethod) => {
            return paymentMethod.type === DIRECT_DEBIT_METHOD && 
                (paymentMethod.can_pay_now || paymentMethod.installment_ids.length > 0)
        });

        return directDebitPaymentMethod !== undefined;
    }

    selectPaymentMethod = (selectedMethod: IPaymentMethod) => {
        if (this.state.selectedPaymentMethod && this.state.selectedPaymentMethod.id === selectedMethod.id) {
            return;
        }

        switch(selectedMethod.type) {
            case "STRIPE":
            case "PAYSAFE_CAD_CC":
            case DIRECT_DEBIT_METHOD:
                this.setState({
                    isOnlinePayment: true,
                    selectedPaymentMethod: selectedMethod,
                    selectedPaymentTime: null,
                    selectedPaymentInstallment: null,
                    hasBillingAddressError: false
                });

                break;
            case "CUSTOM":
                this.setState({
                    isOnlinePayment: false,
                    selectedPaymentMethod: selectedMethod,
                    selectedPaymentTime: null,
                    selectedPaymentInstallment: null,
                    hasBillingAddressError: false
                });

                break;
        }
    }

    selectPaymentTime = (selectedTime: number, shouldShowPaymentTime: boolean = true) => {
        switch(selectedTime) {
            case PAY_NOW:
                this.setState({
                    isInstallment: false,
                    selectedPaymentTime: selectedTime,
                    selectedPaymentInstallment: null,
                    shouldShowPaymentTimeSelection: shouldShowPaymentTime
                });

                break;
            case PAY_INSTALLMENTS:
                this.setState({
                    isInstallment: true,
                    selectedPaymentTime: selectedTime,
                    selectedPaymentInstallment: null,
                    shouldShowPaymentTimeSelection: shouldShowPaymentTime
                });

                break;
            default:
                this.setState({
                    isInstallment: false,
                    selectedPaymentTime: null,
                    selectedPaymentInstallment: null,
                    shouldShowPaymentTimeSelection: shouldShowPaymentTime
                });
                break;
        }
    }

    selectPaymentInstallment = (installmentId: string) => {
        this.setState({
            selectedPaymentInstallment: installmentId,
        });
    }

    saveBillingAddress = (billingAddress: IBillingAddress): Promise<object> => {
        const userId = qidigoAuth.getUserID();
        const uri = `users/${userId}/addresses`;
        const data = {
            address: {
                address: billingAddress.address,
                city_id: billingAddress.city_id,
                country_id: billingAddress.country,
                postal_code: billingAddress.postal_code,
                state_abbreviation: billingAddress.state,
            }
        };

        return qidigoFetch.post(uri, data);
    }

    handlePayment = (token: string) => {
        if (!this.state.selectedBillingAddress) {
            return;
        }

        if (this.state.hasBillingAddressError) {
            return;
        }

        if (!this.state.selectedBillingAddress.willBeSaved) {
            this.props.handlePayment(
                this.state.selectedPaymentMethod,
                this.state.selectedPaymentInstallment,
                token,
            );

            return;
        }

        this.saveBillingAddress(this.state.selectedBillingAddress)
            .then(() => {
                //Do nothing
            }).catch(() => {
            //Do nothing
        }).finally(() => {
            this.props.handlePayment(
                this.state.selectedPaymentMethod,
                this.state.selectedPaymentInstallment,
                token,
            );
        });
    }

    convertToBillingAddress = (address: IAddress): IBillingAddress => {
        return {
            id: address.id,
            apt_no: address.no_apt,
            address: address.address,
            city:  address.city.display_name,
            city_id: address.city.id,
            isCustomCity: false,
            willBeSaved: false,
            state: address.state.abbreviation,
            postal_code: address.postal_code,
            country: address.country_id,
        };
    }

    selectBillingAddress = (address: IBillingAddress|null) => {
        this.setState({
            selectedBillingAddress: address
        });
    }

    fetchBankAccountTransitRestriction = () => {
        if (this.state.banks !== null) {
            return;
        }

        qidigoFetch.fetch('banks', {'method': 'GET'})
        .then((response: Response) => {
            response.json()
                .then((rawBanks: IRawBankResponse[]) => {
                    const banks: IBank[] = [];
                    let newDefaultBank: null|IBank = null;

                    rawBanks.forEach((rawBank: IRawBankResponse) => {
                        const newBank: IBank = {
                            institutionSlug: rawBank.institution_slug,
                            institutionName: rawBank.institution_name,
                            institutionNumber: rawBank.institution_number,
                            transit: {
                                regex: rawBank.transit.regex,
                                placeholder: rawBank.transit.placeholder,
                                error: rawBank.transit.error,
                                fixedValue: rawBank.transit.fixed_value
                            },
                            account: {
                                regex: rawBank.account.regex,
                                placeholder: rawBank.account.placeholder,
                                error: rawBank.account.error,
                            }
                        }

                        if (
                            newDefaultBank === null && 
                            newBank.institutionNumber === DEFAULT_DIRECT_DEBIT_INSTITUTION_ID
                        ) {
                            newDefaultBank = newBank;
                        }
                        banks.push(newBank);
                    });

                    const sortedBankAccount = banks.sort(
                        (a: IBank, b: IBank) => 
                            a.institutionNumber < b.institutionNumber ? -1 : 1
                    );

                    this.setState({
                        banks: sortedBankAccount,
                        defaultBank: newDefaultBank
                    });
                });
            })
    }

    isInstallmentMissing() {
        return this.state.isInstallment
            && this.state.selectedPaymentInstallment === null;
    }

    getPaymentForm(paymentMethod:IPaymentMethod) {

        const {banks, defaultBank} = this.state;

        switch(paymentMethod.type) {
            case "STRIPE":
            case "PAYSAFE_CAD_CC":
                return <CreditCardForm
                    paymentMethods={paymentMethod}
                    // @ts-ignore
                    user_name={this.context.loggedUser.full_name}
                    isInstallmentMissing={this.isInstallmentMissing()}
                    billing_address={this.state.selectedBillingAddress}
                    handleTokenBadRequest={this.props.handleTokenBadRequest}
                    handleOnlinePayment={(token) => this.handlePayment(token)}
                    hasAddressError={this.state.hasBillingAddressError}
                    paymentErrors={this.props.paymentErrors}
                    showPaymentErrorBox={this.props.showPaymentErrorBox}
                    processingPayment={this.props.processingPayment}
                    disabled={this.props.disabled}
                    billingInstallments={this.props.billingInstallments}
                />
            case DIRECT_DEBIT_METHOD:
                return banks === null ? <Loading /> :
                <DirectDebitForm
                    paymentMethods={paymentMethod}
                    // @ts-ignore
                    user_name={this.context.loggedUser.full_name}
                    isInstallmentMissing={this.isInstallmentMissing()}
                    billing_address={this.state.selectedBillingAddress}
                    handleTokenBadRequest={this.props.handleTokenBadRequest}
                    handleOnlinePayment={(token) => this.handlePayment(token)}
                    hasAddressError={this.state.hasBillingAddressError}
                    paymentErrors={this.props.paymentErrors}
                    showPaymentErrorBox={this.props.showPaymentErrorBox}
                    processingPayment={this.props.processingPayment}
                    organizationUuid={this.props.organizationUuid}
                    disabled={this.props.disabled}
                    billingInstallments={this.props.billingInstallments}
                    banks={banks}
                    defaultBank={defaultBank}
                />
            case "CUSTOM":
                return <CustomForm
                    paymentMethods={paymentMethod}
                    isInstallmentMissing={this.isInstallmentMissing()}
                    handlePayment={() => this.props.handlePayment(
                        this.state.selectedPaymentMethod,
                        this.state.selectedPaymentInstallment,
                        null,
                    )}
                    processingPayment={this.props.processingPayment}
                    disabled={this.props.disabled}
                    billingInstallments={this.props.billingInstallments}
                />
        }
    }

    getEligibleInstallments = (paymentMethod: IPaymentMethod|null, installments: IInstallment[]) => {
        let eligibleInstallments = installments
            .filter((installment) => paymentMethod && paymentMethod.installment_ids.includes(installment.id));

        const hasBelow50CentsPayment = installments.length > 0 && installments[0].payments
            .filter((payment) => payment.amount < 0.5).length > 0;

        if (hasBelow50CentsPayment && paymentMethod && paymentMethod.type === 'STRIPE') {
            eligibleInstallments = [];
        }

        return eligibleInstallments;
    }

    isEligiblePaymentMethod = (paymentMethod: IPaymentMethod, installments: IInstallment[]) => {
        return this.isEligibleNowPaymentMethod(paymentMethod)
            || this.getEligibleInstallments(paymentMethod, installments).length > 0
    }

    isEligibleNowPaymentMethod = (paymentMethod: IPaymentMethod) => {
        return paymentMethod.can_pay_now
    }

    setAddressError = (errorList: IAddressError[]) => {
        let hasError: boolean = false;
        if(errorList.length > 0) {
            hasError = true;
        }
        this.setState({
            hasBillingAddressError: hasError
        })
    }

    private showPaymentTimeSelection() : boolean {
        return this.setDefaultSelectedPaymentTime(this.state.selectedPaymentMethod) === null;
    }

    render() {
        const eligibleInstallments = this.getEligibleInstallments(
            this.state.selectedPaymentMethod, 
            this.props.installmentList
        );
        const eligiblePaymentMethods = this.props.paymentMethods.filter(
            (paymentMethod) => this.isEligiblePaymentMethod(paymentMethod, this.props.installmentList)
        );

        return (
            <div className={"payment-container"}>
                <div className="payment-title">
                    <FormattedMessage {...translations["choose_a_payment_method"]} />
                </div>
                <div className="payment--payment-methods-container payment-section">
                    <PaymentMethodList
                        payment_methods={eligiblePaymentMethods}
                        selectPaymentMethod={this.selectPaymentMethod}
                        selectedPaymentMethod={this.state.selectedPaymentMethod}
                    />
                </div>
                {//TODO: refactor: things below can be inside of payment method list
                    this.state.selectedPaymentMethod && eligiblePaymentMethods.length > 0 &&
                    <>
                        {
                            this.showPaymentTimeSelection() &&
                            <>
                                <div className="payment--choose-an-option-title">
                                    <FormattedMessage {...translations["choose_an_option"]} />
                                </div>
                                <div className="payment--payment-time-list-container payment-section">
                                    <PaymentTimeList
                                        canPayNow={this.state.selectedPaymentMethod.can_pay_now}
                                        hasInstallment={eligibleInstallments.length > 0}
                                        selectPaymentTime={this.selectPaymentTime}
                                        selectedPaymentTime={this.state.selectedPaymentTime}
                                    />
                                </div>
                            </>
                        }
                        {
                            this.state.selectedPaymentTime &&
                            <>
                                {
                                    this.state.isInstallment &&
                                    <div className="payment--payment-installment-list-container payment-section">
                                        <PaymentInstallmentList
                                            installmentList={eligibleInstallments}
                                            selectedPaymentInstallment={this.state.selectedPaymentInstallment}
                                            selectPaymentInstallment={this.selectPaymentInstallment}
                                        />
                                    </div>
                                }
                                {
                                    this.state.isOnlinePayment &&
                                    <div className="payment--payment-address-list-container payment-section">
                                        <PaymentAddressList
                                            addressList={this.props.addressList}
                                            selectBillingAddress={this.selectBillingAddress}
                                            selectedBillingAddress={this.state.selectedBillingAddress}
                                            setErrorOnAddress={this.setAddressError}
                                            isPaysafeSelected={
                                                this.state.selectedPaymentMethod.type === 'PAYSAFE_CAD_DD'
                                                || this.state.selectedPaymentMethod.type === 'PAYSAFE_CAD_CC'

                                            }
                                        />
                                    </div>
                                }
                                <div>
                                    {
                                        this.state.selectedPaymentMethod
                                        && this.getPaymentForm(this.state.selectedPaymentMethod)
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

// @ts-ignore
Payment.contextTypes = {
    loggedUser: PropTypes.object,
};

export default Payment;
