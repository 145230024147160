import React                 from "react";
import PropTypes             from "prop-types";
import Title                 from "@app/views/organization/title";
import {navigate}            from "qidigo-router";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import Button                from "qidigo-components/button.js";
import EmptyList             from "@app/components/empty_list";
import HTML                  from "@app/components/html";
import Image                 from "qidigo-components/image.js";
import Loading               from "qidigo-components/loading.js";
import Money                 from "@app/components/money.js";
import {path}                from "@app/lib/routes";
import Date                  from "qidigo-i18n/date";
import GenderRestrictions from "../../GenderRestrictions";

const messages = defineMessages({
	"title": {id: "qidigo.memberships.list.title", defaultMessage: "Abonnements"},
	"viewMore": {id: "qidigo.memberships.list.view_more", defaultMessage: "En savoir plus"},

	"property._pattern":  {id: "qidigo.memberships.list.property._pattern", defaultMessage: "{name}\u00A0: {value}"},
	"property.price": {id: "qidigo.memberships.list.property.price", defaultMessage: "Montant"},
	"property.family": {id: "qidigo.memberships.list.property.family", defaultMessage: "Abonnement famillial"},
	"property.start": {id: "qidigo.memberships.list.property.start", defaultMessage: "Débute"},
	"property.end": {id: "qidigo.memberships.list.property.expire", defaultMessage: "Expire"},
    "property.gender": {id: "qidigo.memberships.list.property.gender", defaultMessage: "Genre"},
    "property.age": {id: "qidigo.memberships.list.property.age", defaultMessage: "Âge:"},

	"priceFrom": {id: "qidigo.memberships.list.price_from", defaultMessage: "à partir de {value}"},

	"start_type.BUY":       {id: "qidigo.memberships.list.start_type.BUY",       defaultMessage: "à l'achat"},
	"start_type.FIRST_USE": {id: "qidigo.memberships.list.start_type.FIRST_USE", defaultMessage: "à la première utilisation"},
	"start_type.FIXED":     {id: "qidigo.memberships.list.start_type.FIXED",     defaultMessage: "le {start_date}"},

	"end_type.BUY": {id: "qidigo.memberships.list.end_type.BUY", defaultMessage: "{end_after} jours après l'achat"},
	"end_type.AFTER_FIXED_START": {id: "qidigo.memberships.list.end_type.AFTER_FIXED_START", defaultMessage: "{end_after} jours après la date de début"},
	"end_type.AFTER": {id: "qidigo.memberships.list.end_type.AFTER", defaultMessage: "{end_after} jours après la première utilisation"},
	"end_type.FIXED": {id: "qidigo.memberships.list.end_type.FIXED", defaultMessage: "le {end_date}"},
	"age.month": {
		id: "qidigo.memberships.list.age.month",
		defaultMessage: "{restriction_age, plural, one {mois} other {mois}}",
	},
	"age.year": {
		id: "qidigo.memberships.list.age.year",
		defaultMessage: "{restriction_age, plural, one {an} other {ans}}",
	},
	"age.range": {
		id: "qidigo.memberships.list.age.range",
		defaultMessage: "entre {min_age} {min_age_unit} et {max_age} {max_age_unit} {target_member}",
	},
	"age.min_only": {
		id: "qidigo.memberships.list.age.min_only",
		defaultMessage: "au moins {min_age} {min_age_unit} {target_member}",
	},
	"age.max_only": {
		id: "qidigo.memberships.list.age.max_only",
		defaultMessage: "au plus {max_age} {max_age_unit} {target_member}",
	},
	"type.family_membership": {
		id: "qidigo.memberships.list.type.family_membership",
		defaultMessage: " (propriétaire du compte)",
	},
	"type.non_family_membership": {
		id: "qidigo.memberships.list.type.non_family_membership",
		defaultMessage: " (abonné)",
	},
});

const AgeRangeMessage = ({membership}) => {
    const isYear = membership['restriction_age_display'] === 'year';
    const minAge = isYear && null !== membership['restriction_age_in_month_min'] ? Math.round(membership['restriction_age_in_month_min'] / 12) : membership['restriction_age_in_month_min'];
    const maxAge = isYear && null !== membership['restriction_age_in_month_max'] ? Math.floor(membership['restriction_age_in_month_max'] / 12) : membership['restriction_age_in_month_max'];
    const ageTextValue = {
        max_age: maxAge,
        min_age: minAge,
        target_member: membership['family_membership']
            ? <FormattedMessage {...messages['type.family_membership']}/>
            : <FormattedMessage {...messages['type.non_family_membership']}/>,
        max_age_unit: isYear
            ? <FormattedMessage {...messages['age.year']} values={{restriction_age: maxAge}}/>
            : <FormattedMessage {...messages['age.month']} values={{restriction_age: maxAge}}/>,
        min_age_unit: isYear
            ? <FormattedMessage {...messages['age.year']} values={{restriction_age: minAge}}/>
            : <FormattedMessage {...messages['age.month']} values={{restriction_age: minAge}}/>
    };

    return (minAge && maxAge && <FormattedMessage {...messages['age.range']} values={ageTextValue}/>)
        || (minAge && <FormattedMessage {...messages['age.min_only']} values={ageTextValue}/>)
        || (maxAge && <FormattedMessage {...messages['age.max_only']} values={ageTextValue}/>)
    ;
}

const MembershipListItem = ({organization, membership, formatMessage}) => {
	const link = path("membership", {orgID: organization["slug"], membershipID: membership["id"]});
	// L'ordre des propriétés est conservé.
	// Ce sont des paires (+1) de key/value. En addition, un paramètre pour indiquer que c'est "sans description".
	const properties = [];
	const classes = ["membership-informations"];

	// Prix
	properties.push(["price",
		<FormattedMessage {...messages["priceFrom"]} values={{value: <Money value={membership["lowest_price"]} />}} />
	]);

	// Début
	properties.push(["start",
		<FormattedMessage {...messages[`start_type.${membership["start_type"]}`]} values={{
			start_date: membership["start_date"] ? <Date date={membership["start_date"]} /> : null,
		}} />,
	]);

	// Fin
	if (membership["end_type"] === "FIXED" && membership["end_date"]) {
		 properties.push(["end",
			 <FormattedMessage {...messages["end_type.FIXED"]} values={{
				 end_date: <Date date={membership["end_date"]} />,
			 }} />,
		 ]);
	}

	if (membership["end_type"] === "AFTER" && membership["end_after"]) {
		let endType = "";
		switch (membership["start_type"]) {
			case "BUY" :
				endType = "end_type.BUY";
				break;
			case "FIXED" :
				endType = "end_type.AFTER_FIXED_START";
				break;
			default :
			  	endType = "end_type.AFTER";
		}

		properties.push(["end",
			<FormattedMessage {...messages[endType]} values={{
				end_after: membership["end_after"],
			}} />,
		]);
	}

	if (membership["family_membership"]) {
		properties.push(["family", <span><FormattedMessage {...messages["property.family"]} /></span>, true]);
	}

    if (membership["gender_restrictions"]) {
        const targetMemberMessage = membership['family_membership']
            ? formatMessage(messages['type.family_membership'])
            : formatMessage(messages['type.non_family_membership']);

		properties.push([
			'gender',
			<GenderRestrictions
				genders={membership['gender_restrictions']}
				targetMemberMessage={targetMemberMessage}
				forPassModal={false}
			/>
		]);
    }

    if (membership["restriction_age_in_month_min"] || membership["restriction_age_in_month_max"]) {
        properties.push(["age", <span><AgeRangeMessage membership={membership} /></span>]);
    }

	if (membership["type_status_id"] !== "OK") {
		classes.push("is-preview");
	}

	return (
		<li className={classes.join(" ")} onClick={()=>navigate(link)}>
			<div>
				<Image className="membership__image" src={membership["media"]["path"]} alt={membership["media"]["text_alt"]} />
				<h3>{membership["name"]}</h3>
				<div className="membership__details">
					<HTML contents={membership["description"]} />
					<ul className="membership__properties">
						{
							properties.map(([key, value, freeForm]) =>
								<li className={`membership__${key}`} {...{key}}>
									{
										freeForm ? <span className="property-freeform">{value}</span> :
										<FormattedMessage
											{...messages["property._pattern"]}
											values={{
												name: <span className="property-key"><FormattedMessage {...messages[`property.${key}`]} /></span>,
												value: <span>{value}</span>,
											}}
										/>
									}
								</li>
							)
						}
					</ul>
				</div>
				<div className="membership__actions">
					<Button link={link}>
						<FormattedMessage
							{...messages["viewMore"]}
						/>
					</Button>
				</div>
			</div>
		</li>
	);
};

MembershipListItem.propTypes = {
	membership: PropTypes.object,
	organization: PropTypes.object,
};

const MembershipList = ({organization, memberships}, {intl: {formatMessage}}) =>
	<section className="memberships-page">
		<h2><FormattedMessage {...messages["title"]} /></h2>
		<Title organization={organization} title={formatMessage(messages["title"])} />
		{
			memberships ?
				<EmptyList className="memberships-list">
					{
						memberships.map((membership, key) =>
							<MembershipListItem {...{key, membership, organization, formatMessage}} />
						)
					}
				</EmptyList>:
				<Loading />
		}
	</section>
	;

MembershipList.propTypes = {
	memberships: PropTypes.arrayOf(MembershipListItem.propTypes.membership),
	organization: PropTypes.object,
};

MembershipList.contextTypes = {
	intl: PropTypes.object,
};

export default MembershipList;
