import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Loading               from "qidigo-components/loading.js";
import GoogleMap             from "google-map-react";
import QidigoMapMarker       from "./mapmarker.js";
import Geo                   from "./index.js";
import {showCity}            from "qidigo-components/city";

/**
 * Component de Map qui wrap largement un bon component qui lui wrap
 * Google Maps.
 *
 * Allez voir la documentation du projet pour comprendre son utilisation.
 *
 *   * https://github.com/istarkov/google-map-react
 *
 * Point principal à garder en tête: la `QidigoMap` n'a pas de hauteur par
 * défaut et prend tout l'espace dans son parent. C'est donc le parent qui
 * *DOIT* avoir une hauteur pour qu'elle apparaisse.
 *
 * **Changer le defaultCenter ne fait rien.** S'il devient nécessaire de
 * re-centrer la carte, il faudra ajouter un fonctionnement le permettant.
 */
class QidigoMap extends Component {
	constructor() {
		super();
		// Garde le defaultCenter en cache pour éviter les warnings du component.
		// (L'objet change même si les valeurs ne changent pas)
		this.state = {
			defaultCenter: {lat: null, lng: null},
			address: null,
		};
	}

	componentWillMount() {
		const {address} = this.props;
		if (address.latitude && address.longitude) {
			const defaultCenter = {
				lat: address.latitude,
				lng: address.longitude,
			};
			this.setState({address, defaultCenter});
		}
		else {
			this.geocodeAddress();
		}
	}

	geocodeAddress() {
		const {address} = this.props;
		let {formatted_address} = address;
		if (!formatted_address) {
			formatted_address += address.address;
			formatted_address += ", " + showCity(address.city);
			formatted_address += ", " + address.country_id;
			formatted_address += ", " + address.postal_code;
		}
		Geo.geocodeAddress(formatted_address)
			.then(({lat, lng}) => {
				let newAddress = Object.assign({}, address, {latitude: lat, longitude: lng});
				const defaultCenter = {lat, lng};
				this.setState({address: newAddress, defaultCenter});

				return defaultCenter;
			})
		;
	}

	render() {
		const {
			className,
			label,
			zoomableScroll,
			...props
		} = this.props;
		const {address} = this.state;
		let {children} = this.props;

		const classes = ["qidigomap", className];

		if (!address || !address.latitude || !address.longitude) {
			return <Loading />;
		}

		// See https://developers.google.com/maps/documentation/javascript/reference?hl=en#MapOptions
		const options = {
			scrollwheel: zoomableScroll,
			fullscreenControl: false,
		};

		if (!children) {
			children =
				<QidigoMapMarker
					lat={address.latitude}
					lng={address.longitude}
				>
					{label}
				</QidigoMapMarker>
			;
		}

		return (
			<div className={classes.join(" ")}>
				<GoogleMap
					defaultCenter={this.state.defaultCenter}
					googleMapLoader={(...e) => Geo.googleMapLoader(...e)}
					{...props}
					options={options}
				>{children}</GoogleMap>
			</div>
		);
	}
}

QidigoMap.propTypes = {
	className: PropTypes.string,
	address: PropTypes.object.isRequired,
	defaultZoom: PropTypes.number,
	zoomableScroll: PropTypes.bool,
	label: PropTypes.string,
};

QidigoMap.defaultProps = {
	defaultZoom: 15,
	zoomableScroll: true,
};

export default QidigoMap;

export {QidigoMapMarker};
