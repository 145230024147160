import React                 from "react";
import Helmet                from "react-helmet";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import Map, {
	QidigoMapMarker as MapMarker
} from "qidigo-geo/map.js";
import Page                  from "@app/components/page.js";
import Telephone             from "qidigo-components/telephone.js";
import moment                from "moment";
import BackToLastLocation    from "@app/components/back-to-last-location.js";

const translations = defineMessages({
	"title": {id: "qidigo.contact.title", defaultMessage: "Nous joindre"},
	"email": {id: "qidigo.contact.email", defaultMessage: "Courriel"},
	"address": {id: "qidigo.contact.address", defaultMessage: "Adresse"},
	"social": {id: "qidigo.contact.social", defaultMessage: "Réseaux sociaux"},
	"telephone": {id: "qidigo.contact.telephone", defaultMessage: "Téléphone"},

	"title.informations": {id: "qidigo.contact.title.informations", defaultMessage: "Coordonnées"},
	"title.schedule": {id: "qidigo.contact.title.schedule", defaultMessage: "Heures d'ouverture"},
	"title.email": {id: "qidigo.contact.title.email", defaultMessage: "Par courriel"},

	"title.mondayfriday": {id: "qidigo.contact.title.mondayfriday", defaultMessage: "Lundi au vendredi"},
	"title.saturdaysunday": {id: "qidigo.contact.title.saturdaysunday", defaultMessage: "Samedi et dimanche"},

	"from_to": {id: "qidigo.contact.from_to", defaultMessage: "{start} à {end}"},
	"closed": {id: "qidigo.contact.closed", defaultMessage: "Fermé"},

	"mail.support": {id: "qidigo.contact.mail.support", defaultMessage: "Pour de l'assistance avec la plate\u2011forme\u00a0:"},
	"mail.other": {id: "qidigo.contact.mail.other", defaultMessage: "Pour toute autre question\u00a0:"},
	"mail.business": {id: "qidigo.contact.mail.business", defaultMessage: "Vous désirez utiliser Qidigo pour votre entreprise\u00a0?"},
});

// FIXME : config.js file for those (See footer)
const address = {
	latitude: 46.7912011,
	longitude: -71.2894272,
	address: "2323 boulevard du Versant-Nord, bur 204",
	city: "Québec",
	state: "QC",
	postal_code: "G1N 4P4",
};
const phone_number = {country_id: "CA", number: "4189900404"};

const schedule = {
	am: ["8:30", "12:00"],
	pm: ["13:00", "17:30"],
};

const time = (time) =>
	moment(`${time}`, "H:mm").format("LT");

const ContactPage = (props, {intl: {formatMessage}}) =>

	<Page name="contact" className="h-card">
		<BackToLastLocation />
		<div className="contact--map">
			<Map
				address={address}
				defaultZoom={15}
				zoomableScroll={false}
			>
				<MapMarker lat={address.latitude} lng={address.longitude}>Qidigo</MapMarker>
			</Map>
		</div>
		<div className="container">
			<section className="contact--box">
				<h1><FormattedMessage {...translations["title"]} /></h1>
				<Helmet
					title={formatMessage(translations["title"])}
				/>
				<div className="contact--details">
					<h2><FormattedMessage {...translations["title.informations"]} /></h2>
					<div className="contact--address">
						<h3><FormattedMessage {...translations.address} /></h3>
						<address>
							<div>{address["address"]}</div>
							<div>
								{address["city"]}{", "}
								{address["state"]}{" "}
								{address["postal_code"]}
							</div>
						</address>
					</div>
					<div className="contact--telephone">
						<h3><FormattedMessage {...translations.telephone} /></h3>
						<Telephone phoneNumber={phone_number} />
					</div>
				</div>

				<div className="contact--schedule">
					<h2><FormattedMessage {...translations["title.schedule"]} /></h2>
					<div className="contact--week">
						<h3><FormattedMessage {...translations["title.mondayfriday"]} /></h3>
						<ul>
							{
								["am", "pm"].map((when) =>
									<li key={when}>
										<FormattedMessage
											{...translations["from_to"]}
											values={{
												start: time(schedule[when][0]),
												end: time(schedule[when][1]),
											}}
										/>
									</li>
								)
							}
						</ul>
					</div>
					<div className="contact--weekend">
						<h3><FormattedMessage {...translations["title.saturdaysunday"]} /></h3>
						<ul>
							<li><FormattedMessage {...translations["closed"]} /></li>
						</ul>
					</div>
				</div>

				<div className="contact--email">
					<h2><FormattedMessage {...translations["title.email"]} /></h2>
					<div className="contact--mail-support">
						<FormattedMessage {...translations["mail.support"]} />
						<a href="mailto:support@qidigo.com">support@qidigo.com</a>
					</div>
					<div className="contact--mail-other">
						<FormattedMessage {...translations["mail.other"]} />
						<a href="mailto:info@qidigo.com">info@qidigo.com</a>
					</div>
					<div className="contact--mail-business">
						<FormattedMessage {...translations["mail.business"]} />
						<a href="https://www2.qidigo.com" target="_blank">www2.qidigo.com</a>
					</div>
				</div>
			</section>
		</div>
	</Page>
;

ContactPage.contextTypes = {
	intl: PropTypes.object,
};

export default ContactPage;
