import React, {Component}  from "react";
import PropTypes             from "prop-types";

class QidigoMapMarker extends Component {
	render() {
		const {className} = this.props;
		const classes = ["mapmarker", className];

		return (
			<div className={classes.join(" ")}><div className="mapmarker--pin" />
				{this.props.children}
			</div>
		);
	}
}

QidigoMapMarker.propTypes = {
	className: PropTypes.string,
};

export default QidigoMapMarker;
