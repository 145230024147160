import React                 from "react";
import {
	Route,
	IndexRoute,
	Redirect,
}   from "react-router/es";
import {
	lazy,
	redirectPrefix,
} from "qidigo-router";

//
// Components pour les routes.
//

// Des fichiers de routes supplémentaires
import SessionRoutes         from "./session";
import OrganizationRoutes    from "./organization";

// Contrôleur de base.
import QidigoApp             from "@app/controllers/application_controller";

// Accueil
import Index                 from "@app/pages/index";
// Page de contact.
import ContactPage           from "@app/views/static/contact";
// 404
import QidiOups              from "@app/pages/404";

import Page from "../components/page";
import FormCartListController from "../controllers/cart/formList";


// Charge conditionnellement la route /debug/ seulement en développement.
// Le code est *retiré* par uglifyjs.
let debugRoutes = null;
if (process.env.NODE_ENV === "development") {
	debugRoutes =
		<Route path="debug">
			<IndexRoute component={require("@app/pages/debug.js").default} />
			<Route path="forms" component={require("qidigo-form/test/page.js").default} />
		</Route>
		;
}

//
// Les routes
// ----------
//
// Il est possible de charger un *préfixe* de routes en *lazy-loading*.
// Ces routes seront chargées au besoin.
//
export default
	<Route key="home" path="/" component={QidigoApp}>
		<IndexRoute component={Index} />
		{SessionRoutes}
		{OrganizationRoutes}
		<Route path="contact" component={ContactPage} />
		<Route
			childRoutes={[
				// Les routes suivantes sont lazy-loadées
				lazy("messages"),
				lazy("dashboard"),
				lazy("registration"),
				lazy("webinar"),
				lazy("terms"),
				lazy("privacy"),
			]}
		/>
		<Route
			childRoutes={[
				// Redirections "legacy"
				redirectPrefix("messenger", "messages"),
				redirectPrefix("profil",    "dashboard"),
			]}
		/>
		<Redirect from="cart/invoice/:id" to="u/:organization_slug/invoices/:id/show" />
		<Route path="*" component={QidiOups} />
	</Route>
	;

/* global require */
