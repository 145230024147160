import * as React from "react";
import {Component} from "react";
import {IAddress, IBillingAddress} from "../../types";
import { defineMessages, FormattedMessage } from "react-intl";


interface IPaymentAddressProps {
    index: number,
    address: IAddress,
    isSelected: boolean,
    selectBillingAddress: (address: IBillingAddress) => void,
    setSelectedAddress:(id: number) => void
}

const translations = defineMessages({
    address: {
        id: "qidigo.billing.payment_address.address",
        defaultMessage: "Adresse"
    },
    choose: {
        id: "qidigo.billing.payment_address.choose",
        defaultMessage: "Choisir"
    },
    selected: {
        id: "qidigo.billing.payment_address.selected",
        defaultMessage: "Sélectionnée"
    },
});

class PaymentAddress extends Component<IPaymentAddressProps> {

    handleSelect = () => {
        this.props.setSelectedAddress(this.props.address.id);
        this.props.selectBillingAddress({
            id: this.props.address.id,
            apt_no: this.props.address.no_apt,
            address: this.props.address.address,
            city: this.props.address.city.display_name,
            city_id: this.props.address.city.id,
            isCustomCity: false,
            willBeSaved: false,
            state: this.props.address.state.abbreviation,
            postal_code: this.props.address.postal_code,
            country: this.props.address.country_id,
        })
    }

    formatAddress(address: IAddress) {
        return <>
            {address.no_apt && <span>{address.no_apt}-</span>}
            {address.address && <span>{address.address} </span>}
            {address.city && <span>{address.city.display_name} </span>}
            {address.state && <span>{address.state.abbreviation} </span>}
            {address.postal_code && <span>{address.postal_code} </span>}
        </>
    }

    render() {
        return (
            <li
                className={"payment-address"}
                key={this.props.address.id}
                onClick={this.handleSelect}
            >
                <div className={"payment-address--container"}>
                    <div className={
                        "payment-address--box"
                        + (this.props.isSelected ? " payment-address--border-selected" : " payment-address--border-not-selected")
                    }>
                        <div className={
                            "payment-address-address-box-title"
                            + (this.props.isSelected ? " payment-address--address-box-selected" : " payment-address--address-box-not-selected")
                        }>
                            <FormattedMessage {...translations["address"]} /> #{this.props.index}
                        </div>
                        <div className={"payment-address--formatted-address"}>
                            {this.formatAddress(this.props.address)}
                        </div>
                        <div className={
                            "payment-address--radio-button-container"
                            + (this.props.isSelected ? " payment-address--address-box-selected" : " payment-address--address-box-not-selected")
                        }>
                            <input
                                className={"payment-address--radio-button"}
                                type="radio"
                                checked={this.props.isSelected}
                                readOnly
                            />
                            <span>
                                {this.props.isSelected
                                    ? <FormattedMessage {...translations["selected"]} />
                                    : <FormattedMessage {...translations["choose"]} />
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default PaymentAddress;
