import * as React from "react";
import {Component} from "react";
// @ts-ignore
import Money from "@app/components/money";
import {ICoupon, IPriceDetail, IAppliedAdjustment, ITax} from "../types";
import {defineMessages, FormattedMessage} from "react-intl";

const translations = defineMessages({
    "applied_credit": {
        id: "qidigo.billing.price_breakdown.credit.applied_credit",
        defaultMessage: "Crédit appliqué"
    },
    "balance": {
        id: "qidigo.billing" +
            ".price_breakdown.balance",
        defaultMessage: "Solde"
    },
    "credit_adjust": {
        id: "qidigo.billing.price_breakdown.credit.adjust",
        defaultMessage: "Ajuster"
    },
    "credit_remove": {
        id: "qidigo.billing.price_breakdown.credit.remove",
        defaultMessage: "Retirer"
    },
    "subtotal": {
        id: "qidigo.billing.price_breakdown.subtotal",
        defaultMessage: "Sous-total avant taxes"
    },

    "total": {
        id: "qidigo.billing.price_breakdown.total",
        defaultMessage: "Total"
    },

    "total_payment": {
        id: "qidigo.billing.price_breakdown.payment_total",
        defaultMessage: "Paiements et crédits déjà appliqués"
    },
    "money": {
        id: "qidigo.billing.line.money",
        defaultMessage: "{money} $"
    }
});

interface IPriceBreakDownProps {
    priceDetail: IPriceDetail,
    open_contact_credit_popup: () => void,
    appliedCoupon: ICoupon | null,
    applied_credit: number,
    refreshCodeWithoutCoupon: () => void,
    balance: number
}

class PriceBreakDown extends Component<IPriceBreakDownProps> {
    appliedAdjustment(adjustment: IAppliedAdjustment) {
        if (adjustment.value === 0.0) {
            return;
        }

        const isCoupon = this.props.appliedCoupon
            && (adjustment.id === this.props.appliedCoupon.adjustment_id);

        return (
            <div>
                <li
                    key={adjustment.id}
                    className={"price-breakdown--line relative"}
                >
                    <div className="price-breakdown--text truncate ..."
                         title={adjustment.name}>{adjustment.name}</div>
                    <div className={"price-breakdown--money"}>
                        <FormattedMessage
                            {...translations["money"]}
                            values={{
                                money: <Money value={adjustment.value} withSymbol={false}/>
                            }}
                        />
                    </div>
                </li>
                {
                    isCoupon &&
                    <button
                        className={"price-breakdown--coupon-remove-button button-link"}
                        onClick={() => this.props.refreshCodeWithoutCoupon()}
                    >
                        (<FormattedMessage {...translations["credit_remove"]} />)
                    </button>
                }
            </div>
        );
    }

    appliedTax(tax: ITax) {
        return (
            <li
                key={tax.id}
                className={"price-breakdown--line"}
            >
                <span>
                    {tax.name} ({tax.rate}%)
                </span>
                <div className={"price-breakdown--money"}>
                    <FormattedMessage {...translations["money"]}
                                      values={{
                                          money: <Money value={tax.value} withSymbol={false}/>
                                      }}
                    />
                </div>
            </li>
        );
    }

    render() {
        const paymentsAndCredits = this.props.priceDetail.total - this.props.priceDetail.balance

        return (
            <div className={"price-breakdown"}>
                <ul>
                    <li
                        key={"subtotal_before_taxes"}
                        className={"price-breakdown--line"}
                    >
                        <span className={"price-breakdown--text"}>
                            <FormattedMessage {...translations["subtotal"]} />
                        </span>
                        <div className={"price-breakdown--money"}>
                            <FormattedMessage {...translations["money"]}
                                              values={{
                                                  money: <Money value={this.props.priceDetail.subtotal}
                                                                withSymbol={false}/>
                                              }}
                            />
                        </div>
                    </li>
                    {
                        this.props.priceDetail.adjustments.map(
                            (adjustment: IAppliedAdjustment) => this.appliedAdjustment(adjustment)
                        )
                    }
                    {
                        this.props.priceDetail.taxes.map((tax: ITax) => this.appliedTax(tax))
                    }
                    {
                        this.props.priceDetail.total !== this.props.balance &&
                        <li
                            key={"total"}
                            className={"price-breakdown--line"}
                        >
                            <span className={"price-breakdown--text billing-total"}>
                                <FormattedMessage {...translations["total"]} />
                            </span>
                            <div className={"price-breakdown--money billing-total"}>
                                <FormattedMessage
                                    {...translations["money"]}
                                    values={{
                                        money: <Money value={this.props.priceDetail.total}
                                                      withSymbol={false}/>
                                    }}
                                />
                            </div>
                        </li>
                    }
                    {
                        paymentsAndCredits > 0 &&
                        <li
                            key={"total_payments"}
                            className={"price-breakdown--line"}
                        >
                            <span className={"price-breakdown--text"}>
                                <FormattedMessage {...translations["total_payment"]} />
                            </span>
                            <div className={"price-breakdown--money"}>
                                <FormattedMessage
                                    {...translations["money"]}
                                    values={{
                                        money: <Money
                                            value={this.props.priceDetail.total - this.props.priceDetail.balance}
                                            withSymbol={false}
                                        />
                                    }}
                                />
                            </div>
                        </li>
                    }
                    {
                        this.props.applied_credit > 0 &&
                        <li className={"price-breakdown--li"}
                            key={"applied_credit"}
                        >
                            <div className={"price-breakdown--line relative"}>
                            <span className={"price-breakdown--text"}>
                                <FormattedMessage {...translations["applied_credit"]} />
                            </span>
                                <div className={"price-breakdown--money"}>
                                    <FormattedMessage {...translations["money"]}
                                                      values={{
                                                          money: <Money
                                                              value={this.props.applied_credit.toFixed(2)}
                                                              withSymbol={false}/>
                                                      }}
                                    />
                                </div>
                            </div>
                            <button
                                className={"price-breakdown--credit-adjust button-link"}
                                onClick={() => this.props.open_contact_credit_popup()}
                            >
                                <FormattedMessage {...translations["credit_adjust"]} />
                            </button>
                        </li>
                    }
                    <div className={"billing-total"}>
                        <span>
                            <FormattedMessage {...translations['balance']}/>
                        </span>
                        <div className={"money"}>
                            <FormattedMessage
                                {...translations["money"]}
                                values={{
                                    money: <Money value={
                                        this.props.balance.toFixed(2)} withSymbol={false}
                                    />
                                }}
                            />
                        </div>

                    </div>
                </ul>
            </div>
        )
    }
}

export default PriceBreakDown;
