import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {errorMessagesType}   from "./proptypes.js";
import mapValues             from "lodash/mapValues";
import pickBy                from "lodash/pickBy";
import T                     from "qidigo-i18n/messages";

/**
 * Component générique de formulaire.
 *
 * Ce component a un comportement particulier avec ses children.
 * Il peut configurer un className, un handler onChange, le status disabled,
 * les valeurs et les erreurs des éléments directement enfants.
 *
 * Ce comportement permet surtout de raccourcir le code des formulaires.
 *
 * Une amélioration future sera de permettre aussi à des enfants plus creux
 * dans la hiérarchie d'être manipulés.
 *
 * @extends {Component}
 */
class Form extends Component {
	constructor() {
		super();

		// Référence à tous les `children` que le `Form` peut normalement
		// gérer. C'est-à-dire valider, etc.
		// NE PAS UTILISER COMME LISTE DES CHAMPS. Toujours utiliser les
		// données passées au `Form` et utiliser cet object de refs pour
		// accéder aux références.
		this.allRefs = {};
	}

	handleSubmit(e) {
		// On prend pour acquis que tous nos formulaires prevendDefault();
		// Si ça devient nécessaire de ne pas preventDefault, on ajoutera un prop.
		e.preventDefault();

		// (1) Valider le formulaire...
		const errors = this.validate();

		// Des erreurs? On ne continue pas!
		if (Object.keys(errors.fields).length > 0 || errors.message) {
			return this.handleValidationError(errors);
		}

		// Autrement, on envoie le tout au handler par défaut.
		return this.props.onSubmit(...e);
	}

	/**
	 * Formatte un message d'erreur général avec les messages des champs.
	 */
	formatErrorMessage(errors) {
		const {intl: {formatMessage}} = this.context;
		const {allRefs} = this;
		// Mappe l'objet par keys...
		const messages = Object.keys(errors.fields || {}).map((k) => {
			// Pour y trouver la collection des messages,
			const messages = errors.fields[k];
			// sa référence,
			const ref = allRefs[k];
			// un label à utiliser
			let attribute = "";
			if (ref) {
				attribute = ref.props.label ? ref.props.label : ref.props.name;
			}

			// Et formatter.
			return `${messages.map((message)=>
				formatMessage(T["errors.format"], {
					attribute, message,
				})
			).join(", ")}`;
		});

		// Pour ensuite tout formatter.
		return formatMessage(T["activerecord.errors.messages.record_invalid"], {
			errors: messages.join(", "),
		});
	}

	/**
	 * Effectue la validation du formulaire, en appelant les fonctions
	 * `validate()` des enfants et ensuite, celle en propriété.
	 */
	validate() {
		// Contiendra l'objet de forme `errorMessagesType`.
		let errors = {
			message: false,
			fields: {},
		};

		// (2) Effectue la validation des champs.
		// Il s'agit de la validation "native" du champ, par exemple,
		// `required` ou encore le bon format avant l'envoi.

		// Pour chaque ref, valide.
		errors.fields = mapValues(this.allRefs,
			// S'il sait comment se valider...
			// Les arrays vides passés facilitent la vie de onValidate...
			// Il est possible d'uniquement concaténer avec Array.concat().
			(ref) => ref && ref.validate ? ref.validate() : []
		);

		// (3/3×) appel de la validation passée, avec ce qu'on a maintenant.
		if (this.props.onValidate) {
			errors = this.props.onValidate(errors);
		}

		// Filtre les messages vides
		errors.fields = pickBy(errors.fields, (v) => v.length > 0);

		// Formatte le message d'erreur (si on en a besoin).
		if (!errors.message && Object.keys(errors.fields).length > 0) {
			errors.message = this.formatErrorMessage(errors);
		}

		return errors;
	}

	/**
	 * S'occupe de propager vers le haut (5).
	 */
	handleValidationError(errors) {
		if (!this.props.onValidationError) {
			// Message pendant le développement.
			/* eslint-disable */
			if (process.env.NODE_ENV === "development") {
				console.error("<Form> is missing onValidationError whilst it should have one.");
				console.info("Validation errors missed: ", errors)
			}
			/* eslint-enable */

			return null;
		}

		return this.props.onValidationError(errors);
	}

	render() {
		let {
			// Skip some items for the html `<form>`
			/* eslint-disable */
			intl,
			onValidate,
			onValidationError,
			onSubmit,
			/* eslint-enable */
			children,
			className,
			onChange,
			disabled,
			errors,
			values,
			presentation,
			...leftoverProps
		} = this.props;

		// Classes appliquées au wrapper.
		let classes = ["form", className];
		let baseClassName = className.split(" ")[0];

		// Create a local function to manipulate children
		// (It needs access to this scope)
		const childMangler = (child) => {
			// Handle weird children; not React components.
			if (!(child && child.props)) { return child; }

			// Does it looks like one of the input we handle?
			if (!child.props.name) {
				// No?

				// Skip child-less elements
				if (!child.props.children) {
					return child;
				}

				// We'll handle them in their children!
				// FIXME : Be more precise in *what elements* we traverse?
				const children = React.Children.map(child.props.children, childMangler);

				// Return a shallow clone with new additional props.
				return React.cloneElement(child, {children});
			}
			const newAdditionalProps = {};
			const name = child.props.name;

			// Sets a class by convention.
			if (!child.props.className) {
				newAdditionalProps.className = `${baseClassName}--${name}`;
			}

			// Ajoute la présentation du `<Form>` à un child qui n'en a pas.
			if (presentation !== undefined && child.props.presentation === undefined) {
				newAdditionalProps.presentation = presentation;
			}

			// Append errors to it.
			if (errors && errors.fields && errors.fields[name]) {
				const error = errors.fields[name];
				if (error.join) {
					newAdditionalProps.error = error.join(", ");
				}
			}

			// Disables it if form is disabled.
			if (disabled && !child.props.disabled) {
				newAdditionalProps.disabled = disabled;
			}

			// Sets its value, if possible, taking caution with falsey values.
			// It is assumed form controls are *controlled*.
			// This is why we force an empty string otherwise.
			if (values && values[name] !== undefined && values[name] !== null) {
				newAdditionalProps.value = values[name];
			}
			// Do note that if the field has its own `value` property, we
			// are not replacing it.
			else if (child.props.value === undefined) {
				if (child.props.defaultValue) {
					newAdditionalProps.value = child.props.defaultValue;
				}
				else if (child.type.defaultValue !== undefined) { // eslint-disable-line
					newAdditionalProps.value = child.type.defaultValue;
				}
				else {
					newAdditionalProps.value = "";
				}
			}

			// Conserve une ref.
			// ATTENTION : utiliser inputRef sur <Input /> pour le ref.
			newAdditionalProps.ref = (node) => this.allRefs[name] = node;

			// Hooks it to a change handler.
			if (onChange && !child.props.onChange) {
				newAdditionalProps.onChange = (...e) => onChange(name, ...e);
			}

			// Return a shallow clone with new additional props.
			return React.cloneElement(child, newAdditionalProps);
		};

		// Manipulate children.
		children = React.Children.map(children, childMangler);

		// Prepare the errorMessage.
		let errorMessage = null;
		if (errors && errors.message) {
			errorMessage =
				<div className="error-message">
					{errors.message}
				</div>
			;
		}
		else if (errors) {
			errorMessage =
				<div className="error-message">
					{this.formatErrorMessage(errors)}
				</div>
			;
		}

		return (
			<form className={classes.join(" ")}
				{...leftoverProps}
				onSubmit={(e)=>this.handleSubmit(e)}
				noValidate={true} /* On valide nous-même. */
			>
				{errorMessage}
				{children}
			</form>
		);
	}
}

Form.propTypes = {
	className:          PropTypes.string,
	presentation:       PropTypes.string,
	onValidate:         PropTypes.func,
	onValidationError:  PropTypes.func,
	onSubmit:           PropTypes.func,
	onChange:           PropTypes.func,
	disabled:           PropTypes.bool,
	values:             PropTypes.object,
	errors:             PropTypes.oneOfType([
		errorMessagesType,
		PropTypes.bool,
	]),
};

Form.defaultProps = {
	className: ""
};

Form.contextTypes = {
	intl: PropTypes.object,
};

export default Form;
