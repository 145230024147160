import EmptyList from '@app/components/empty_list'
import Money from '@app/components/money.js'
import AgeRestriction from '@app/views/shared/age_restriction'
import FamilyMultiSelector from '@app/views/shared/family/multi_selector'
import DurationFragment from '@app/views/shared/groups/duration'
import RemainingPlacesWidget from '@app/views/shared/groups/remaining_places'
import ScheduleFragment from '@app/views/shared/groups/schedule'
import { translations as offerFormTranslations } from '@app/views/shared/offers/_offerform'
import PropTypes from 'prop-types'
import Button from 'qidigo-components/button.js'
import Image from 'qidigo-components/image.js'
import ButtonsBar from 'qidigo-form/buttons_bar'
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import {navigate}            from "qidigo-router";

import Title from './Title'
import GenderRestrictions from "../GenderRestrictions";
import QidigoBackLocation from "../../modules/qidigo-router/back_location";

const translations = defineMessages({
  "page.title": {
    id: "qidigo.activity.groups.page.title.groups",
    defaultMessage: "2. Sélectionner les groupes"
  },
  "page.title.attendee": {
    id: "qidigo.activity.groups.page.title.attendee",
    defaultMessage: "1. Choisir un participant à inscrire"
  },
  "page.tout.selectionner": {
    id: "qidigo.activity.groups.page.tout.selectionner",
    defaultMessage: " Tout sélectionner"
  },
  "page.deselectionner.all": {
    id: "qidigo.activity.groups.page.deselectionner.all",
    defaultMessage: " Tout désélectionner"
  },
  view_more: {
    id: "qidigo.activity.groups.view_more",
    defaultMessage: "En savoir plus"
  },
  view: {
    id: "qidigo.activity.groups.view",
    defaultMessage: "Voir le groupe",
    description: "Message pour l'accessibilité du lien"
  },
  price_from: {
    id: "qidigo.activity.groups.price_from",
    defaultMessage: "À partir de "
  },
  "informations.activity": {
    id: "qidigo.activity.groups.informations.activity",
    defaultMessage: "Activité"
  },
  "informations.schedule": {
    id: "qidigo.activity.groups.informations.schedule",
    defaultMessage: "Plage horaire\u00A0:\u00A0"
  },
  "informations.duration": {
    id: "qidigo.activity.groups.informations.duration",
    defaultMessage: "Durée\u00A0:\u00A0"
  },
  adding: {
    id: "qidigo.activity.groups.offers.adding",
    defaultMessage: "Ajout en cours..."
  },
  "informations.remaining_places": {
    id: "qidigo.activity.groups.informations.remaining_places",
    defaultMessage: "Places restantes\u00A0:\u00A0"
  },
  "restriction.gender": {
    id: "qidigo.activity.groups.restriction.gender",
    defaultMessage: "Genre\u00A0:\u00A0"
  },
  "restriction.age": {
    id: "qidigo.activity.groups.restriction.age",
    defaultMessage: "Âge\u00A0:\u00A0"
  },
  "btn.next": {
    id: "qidigo.activity.groups.btn.next",
    defaultMessage: "Étape suivante"
  },
  "pending.list": {
    id: "qidigo.activity.groups.pending.list",
    defaultMessage: "Afficher les groupes complets ayant une liste d'attente"
  }
});

const GroupsView = (props, context) => {
  let {
    activity,
    organization,
    groups,
    members,
    selectedMembers,
    handleSelectMember,
    handleSelectGroups,
    handleAddToCart,
    handleSelectAllGroups,
    handleCompleteGroups,
    groupsWaitingList,
    completeGroups,
    disabledFullGroupsCheckbox,
    disabled,
    activateForm,
    isLoggedIn,
    selectAll,
    hideSelectAllDefault,
    groupsSelected,
    refreshFamilyMembers
  } = props;

  const { formatMessage } = context.intl;

  const groupsSelect = groupsSelected;
  const label = groupsSelect.length == groups.length ? "page.deselectionner.all" : "page.tout.selectionner";

  function goToLogin() {
    QidigoBackLocation.saveLastLocation();
    navigate('/login');
  }

  const LoginComp = props => {
    return (
      <div className="margin-1rem">
        <Button onClick={goToLogin}>
          <FormattedMessage {...offerFormTranslations["guest.login"]} />
        </Button>
      </div>
    );
  };

  if (isLoggedIn === false || (selectedMembers && selectedMembers.length === 0)) {
    activateForm = true;
    disabled = true;
  }

  const buttons = (
    <ButtonsBar disabled={true} withoutSubmit={true}>
      {
          (!hideSelectAllDefault || groupsSelect.length == groups.length) && <Button
          disabled={activateForm}
          onClick={e => handleSelectAllGroups(selectAll)}
          className={activateForm ? " not-allowed ButtonsBar-left form-ButtonsBar-sm" : "ButtonsBar-left form-ButtonsBar-sm"}
        >
          <FormattedMessage {...translations[label]} />
        </Button>
      }
      <Button
        onClick={e => handleAddToCart(members)}
        disabled={disabled}
        className={activateForm ? "not-allowed" : ""}
      >
        <FormattedMessage {...translations["btn.next"]} />
      </Button>
    </ButtonsBar>
  )

  return (
    <div className="activity-groups-list ">
      <Title
        title={formatMessage(translations["page.title.attendee"])}
        activity={activity}
        organization={organization}
      />
      <section>
        <h2 className="page-title-attendee">
          <FormattedMessage {...translations["page.title.attendee"]} />
        </h2>
        {
          isLoggedIn === false && (
            <LoginComp />
          )
        }
        {
          isLoggedIn !== false && (
            <FamilyMultiSelector
              shouldAllowAddMember
              onNewMemberAdded={refreshFamilyMembers}
              options={Object.values(members)}
              selectedOptions={selectedMembers}
              handleChange={handleSelectMember}
            />
          )
        }
        <label className="span-checkbox-view-complete">
          <input
            id="radio-1"
            onChange={handleCompleteGroups}
            value={completeGroups}
            type="checkbox"
            defaultChecked={completeGroups}
			disabled={disabledFullGroupsCheckbox}
          />
          <FormattedMessage {...translations["pending.list"]} />
        </label>
        <hr />
        <h2>
          <FormattedMessage {...translations["page.title"]} />
        </h2>

        {buttons}
        <EmptyList>
          {groups.map((group, key, index) => {
            const classes = ["activity-group-fragment"];

            if (group["type_status_id"] !== "OK") {
              classes.push("is-preview");
            }
            let checked = false;

            for (let index = 0; index < groupsSelected.length; index++)
              if (groupsSelected[index] == group.id) checked = true;

            for (let j = 0; j < groupsWaitingList.length; j++) {
              if (groupsWaitingList[j] == group.id) return null;
            }

            if (isLoggedIn === false || (selectedMembers && selectedMembers.length === 0)) {
              checked = false;
            }

            return (
              <li className={classes.join(" ")} key={group.id}>
                <label htmlFor={group.id} className={activateForm ? "not-allowed " : ""} >
                  <div>
                    <div className="inputGroup">
                      <input
                        className={activateForm ? "not-allowed " : ""}
                        disabled={activateForm}
                        type="checkbox"
                        value={group.id}
                        name="groups[]"
                        id={group.id}
                        onChange={handleSelectGroups}
                        checked={checked}
                      />
                      <label htmlFor={group.id} className={activateForm ? "not-allowed" : ""} >
                        <h3 className={activateForm ? "not-allowed " : ""}>
                          {group.name}
                        </h3>
                        <div className={activateForm ? "activity-group-fragment--image not-allowed " : "activity-group-fragment--image"}  >
                          <Image src={group.media.path} alt={group.media.text_alt} />
                        </div>
                        <ul className={activateForm ? "activity-group-fragment--informations not-allowed " : "activity-group-fragment--informations"} >
                          <li>
                            <h4>
                              <FormattedMessage {...translations["informations.duration"]} />
                            </h4>
                            <DurationFragment duration={group.duration} capitalized={false} />
                          </li>
                          <li>
                            <h4>
                              <FormattedMessage {...translations["informations.schedule"]} />
                            </h4>
                            <ScheduleFragment times={group.schedule} key={group.id} />
                          </li>
                          <li>
                            <h4>
                              <FormattedMessage  {...translations["informations.remaining_places"]} />
                            </h4>
                            <RemainingPlacesWidget num={group.nbr_places_remaining} />
                          </li>
                          <li>
                            <h4>
                              <FormattedMessage {...translations["restriction.gender"]} />
                            </h4>
                            <span>
                                <GenderRestrictions
                                    genders={group.restrictions_gender}
                                    targetMemberMessage={""}
                                    forPassModal={false}
                                />
                            </span>
                          </li>
                          <li>
                            <h4>
                              <FormattedMessage {...translations["restriction.age"]} />
                            </h4>
                            <span>
                              <AgeRestriction min={group.restriction_age_min} max={group.restriction_age_max} />
                            </span>
                          </li>
                        </ul>
                        <div className={activateForm ? "activity-group-fragment--price not-allowed " : "activity-group-fragment--price"} >
                          <strong>
                            <FormattedMessage {...translations["price_from"]} />
                          </strong>
                          <Money value={group["lowest_price"]} />
                        </div>
                      </label>
                    </div>
                  </div>
                </label>
              </li>
            );
          })}
        </EmptyList>

        {buttons}
      </section>
    </div>
  );
};

GroupsView.propTypes = {
  offers: PropTypes.object,
  activity: PropTypes.object,
  organization: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  handleAddToCart: PropTypes.func.isRequired,
  activateForm: PropTypes.bool,
  selected: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  disabled: PropTypes.bool,
  handleSelectGroups: PropTypes.func.isRequired,
  handleSelectMember: PropTypes.func.isRequired,
  handleSelectAllGroups: PropTypes.func.isRequired,
  handleCompleteGroups: PropTypes.func.isRequired,
  refreshFamilyMembers: PropTypes.func.isRequired
};

GroupsView.defaultProps = {};
GroupsView.contextTypes = {
  intl: PropTypes.object
};

export default GroupsView;
