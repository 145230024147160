import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";
import Form                  from "qidigo-form/form";

import {
	mapOptionsTranslations
} from "qidigo-i18n";
import CityInput from "@app/components/city_input";

// FIXME : Utiliser une réponse de l'API (reduxed) au lieu des listes hardcodées
// (Une fois le data cleané)
import COUNTRIES             from "qidigo-data/countries";
import STATES                from "qidigo-data/states";

//
// Valeurs présentes comme constantes pour simplifier la lecture.
//
// Il faudrait éventuellement tenter de trouver ou deviner la localisation de
// l'utilisateur.
//
const DEFAULT_COUNTRY = "CA";
const DEFAULT_STATE = "QC";

const FIELDS = {
	ADDRESS: 'address',
	NO_APT: 'no_apt',
	POSTAL_CODE: 'postal_code',
	CITY: 'city',
	COUNTRY: 'country',
	STATE: 'state'
}

const fieldNames = defineMessages({
	[FIELDS.ADDRESS]:     {id: "qidigo.address_form.label.address", defaultMessage: "Numéro civique et rue"},
	[FIELDS.NO_APT]:      {id: "qidigo.address_form.label.no_apt", defaultMessage: "N° app."},
	[FIELDS.POSTAL_CODE]: {id: "qidigo.address_form.label.postal_code", defaultMessage: "Code postal"},
	[FIELDS.CITY]:        {id: "qidigo.address_form.label.city", defaultMessage: "Ville"},
	[FIELDS.STATE]:       {id: "qidigo.address_form.label.state", defaultMessage: "Province"},
	[FIELDS.COUNTRY]:     {id: "qidigo.address_form.label.country", defaultMessage: "Pays"},
});

class AddressPartial extends Component {
	/**
	 * Envoie les valeurs par défaut pour initialiser l'objet.
	 * Attache le *listener* de complétion.
	 */
	componentDidMount() {
		const {onChange, value} = this.props;
		if (!value || !value.country) { onChange(FIELDS.COUNTRY, {}, DEFAULT_COUNTRY); }
		if (!value || !value.state)   { onChange(FIELDS.STATE, {}, DEFAULT_STATE); }
	}

	/**
	 * Proxy par-dessus `onChange` pour faire quelques traitements:
	 *   * Filtrer la province / L'état dans les objects de l'API.
	 */
	handleChange = (field, e, value) => {
		const {onChange} = this.props;
		// Proxyfie.
		onChange(field, e, value);
	}

	/**
	 * Proxy pour modifier les values envoyées à <Form>...
	 */
	values() {
		const values = Object.assign({}, this.props.value);
		if (values.state && values.state["abbreviation"]) {
			values.state = values.state["abbreviation"];
		}

		return values;
	}

	render() {
		const {formatMessage} = this.context.intl;
		const {
			autoFocus,
			value,
			childrenBefore,
			children,
			className,
			// Keep from going in leftOverProps
			onChange, // eslint-disable-line
			...leftOverProps
		} = this.props;

		const classPrefix = "form-partial-address";
		const classNames = [classPrefix, className];

		const values = this.values();

		// Country pour CityInput
		const country = value && value.country ? value.country : DEFAULT_COUNTRY;
		// State pour CityInput
		const state = values && values.state ? values.state : DEFAULT_STATE;

		return (
			<Form
				className={classNames.join(" ")}
				values={values}
				{...leftOverProps}
				onChange={this.handleChange}
			>
				{childrenBefore}
				<Form.Row
					className={`${classPrefix}--first_row`}
				>
					<Form.Input
						name={FIELDS.ADDRESS}
						value={values[FIELDS.ADDRESS]}
						autoComplete="street-address"
						required={true}
						label={formatMessage(fieldNames.address)}
						autoFocus={autoFocus}
						className={`${classPrefix}--address`}
					/>
					<Form.Input
						name={FIELDS.NO_APT}
						autoComplete="foo"
						label={formatMessage(fieldNames.no_apt)}
						className={`${classPrefix}--no_apt`}
					/>
				</Form.Row>
				<Form.Row
					className={`${classPrefix}--second_row`}
				>
					<Form.Select
						name={FIELDS.COUNTRY}
						required={true}
						autoComplete="country"
						label={formatMessage(fieldNames.country)}
						options={mapOptionsTranslations(formatMessage, COUNTRIES)}
					/>
					<Form.Select
						name={FIELDS.STATE}
						required={true}
						autoComplete="address-level1"
						label={formatMessage(fieldNames.state)}
						options={mapOptionsTranslations(formatMessage, STATES[country])}
					/>
				</Form.Row>
				<Form.Row
					className={`${classPrefix}--last_row`}
				>
					<CityInput
						name={FIELDS.CITY}
						autoComplete="foo"
						required={true}
						label={formatMessage(fieldNames.city)}
						inputRef={(node) => this.cityNode = node}
						country={country}
						state={state}
					/>
					<Form.Input
						name={FIELDS.POSTAL_CODE}
						autoComplete="postal-code"
						required={true}
						label={formatMessage(fieldNames.postal_code)}
					/>
				</Form.Row>
				{children}
			</Form>
		);
	}
}

AddressPartial.contextTypes = {
	intl: PropTypes.object,
};

AddressPartial.propTypes = {
	// FIXME : shape it
	value: PropTypes.object,
	className: PropTypes.string,
	childrenBefore: PropTypes.node,
	onChange: PropTypes.func,
	autoFocus: PropTypes.bool,
};

AddressPartial.defaultProps = {
	autoFocus: false,
};

export default AddressPartial;
