import * as React from "react";
import {Component} from "react";
// @ts-ignore
import TextField from "../../common/components/forms/TextField"
import {defineMessages, FormattedMessage} from "react-intl";

// @ts-ignore
import Image from 'qidigo-components/image.js'
// @ts-ignore
import {ICourseDescription} from "../types";
// @ts-ignore
import PropTypes from "prop-types";

// @ts-ignore
import AgeRestriction from "@app/views/shared/age_restriction";

// @ts-ignore
import DurationFragment from "@app/views/shared/groups/duration";
// @ts-ignore
import HTML from "@app/components/html";
// @ts-ignore
import ScheduleFragment from '@app/views/shared/groups/schedule'
import {locale} from "moment";
import GenderRestrictions from "../../GenderRestrictions";


const host = process.env.NODE_ENV === "development" ? "http://localhost:8000/" : "qidigo.com";

const translations = defineMessages({
    "group_description": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description",
        defaultMessage: "Présentation du cours"
    },
    "full": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.full",
        defaultMessage: "Complet"
    },
    "schedule": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.schedule",
        defaultMessage: "Horaire"
    },
    "date_range": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.date_range",
        defaultMessage: "Du {start_date} au {end_date}"
    },
    "schedule_time": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.from_to",
        defaultMessage: "{day} {start_time} - {end_time}"
    },
    "restrictions": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.restriction",
        defaultMessage: "Restrictions\u00A0:"
    },
    "place": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.place",
        defaultMessage: "Place\u00A0:"
    },
    "gender": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.gender",
        defaultMessage: "Genre\u00A0:"
    },
    "age_range": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.age_range",
        defaultMessage: "{min_age} à {max_age} ans"
    },
    "age": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.age",
        defaultMessage: "Âge\u00A0:"
    },
    "plus_places": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.plus_places",
        defaultMessage: "{places} et +"
    },
    "unlimited": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.unlimited",
        defaultMessage: "aucune limite"
    },
    "years": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.years",
        defaultMessage: " ans"
    },
    "time_window_restriction": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.time_window_restriction",
        defaultMessage: "La réservation pour ce cours sera disponible à partir du {date}"
    },
    "registration_period_restriction": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.registration_period_restriction",
        defaultMessage: "La période d'achat pour les passes de ce cours commence à partir du {date}"
    }
})

const days = defineMessages({
    "0": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.sunday",
        defaultMessage: " Dimanche"
    },
    "1": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.monday",
        defaultMessage: " Lundi"
    },
    "2": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.tuesday",
        defaultMessage: " Mardi"
    },
    "3": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.wednesday",
        defaultMessage: " Mercredi"
    },
    "4": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.thursday",
        defaultMessage: " Jeudi"
    },
    "5": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.friday",
        defaultMessage: " Vendredi"
    },
    "6": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.saturday",
        defaultMessage: " Samedi"
    },
})

const MAX_PLACES_DISPLAY = 5;

interface ICourseDescriptionProps {
    courseDescription: ICourseDescription;
}

interface ICourseDescriptionState {
    showRegistrationDateRestriction: boolean,
    restrictedRegistrationDate: Date | string,
    restrictedRegistrationText: string,
    isGroupFull: boolean,
    placesText: string,
}

class CourseDescription extends Component<ICourseDescriptionProps, ICourseDescriptionState> {
    constructor(props: ICourseDescriptionProps) {
        super(props);
        this.state = {
            isGroupFull: false,
            placesText: "",
            restrictedRegistrationDate: '',
            restrictedRegistrationText: '',
            showRegistrationDateRestriction: false
        }
    }

    componentDidMount() {
        this.setCoursePlacesRestriction()
        this.setRegistrationRestriction()
    }


    getSchedule = (startDateString, endDateString) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        const startTime = startDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
        const endTime = endDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});

        return [
            {
                day: startDate.getDay(),
                hours: [
                    [
                        startTime,
                        endTime
                    ]
                ]
            }
        ]
    };

    getFormattedDate = (dateString: string | Date): string => {
        const date = new Date(dateString);

        const formattedDate = date.toLocaleDateString(locale(), {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

        const formattedTime = date.toLocaleTimeString(locale(), {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });

        return `${formattedDate} ${formattedTime}`;
    }

    setCoursePlacesRestriction = () => {
        const isUnlimited = this.props.courseDescription.places === null;
        const nbPlacesRemaining = this.props.courseDescription.places_remaining;
        let isGroupFull = false;
        let placesText: any = <FormattedMessage {...translations['unlimited']} />;

        if (!isUnlimited && nbPlacesRemaining !== null) {
            isGroupFull = !isUnlimited ? nbPlacesRemaining <= 0 : false;
            placesText = nbPlacesRemaining > MAX_PLACES_DISPLAY
                ? MAX_PLACES_DISPLAY + " +"
                : nbPlacesRemaining
            if (isGroupFull) {
                placesText = <FormattedMessage {...translations['full']} />;
            }
        }

        this.setState({
            isGroupFull: isGroupFull,
            placesText: placesText,
        })
    }

    setRegistrationRestriction = () => {
        let showRegistrationDateRestriction = false;
        let restrictedRegistrationDate : Date | string = '';
        let restrictedRegistrationText = '';
        const today = new Date();

        if (this.props.courseDescription.upComingRegistrationDate) {
            const upComingRegistrationDate = new Date(this.props.courseDescription.upComingRegistrationDate);
            showRegistrationDateRestriction = upComingRegistrationDate > today;
            restrictedRegistrationDate = showRegistrationDateRestriction ? upComingRegistrationDate : '';
            restrictedRegistrationText = 'registration_period_restriction'
        }

        if (this.props.courseDescription.timeWindow &&  this.props.courseDescription.timeWindow.before) {
            const timeWindowDate = new Date(this.props.courseDescription.timeWindow.before);
            showRegistrationDateRestriction =
                this.props.courseDescription.timeWindow
                && this.props.courseDescription.timeWindow.type === 'time_window'
                && timeWindowDate > today

            restrictedRegistrationDate = showRegistrationDateRestriction ? timeWindowDate : '';
            restrictedRegistrationText = 'time_window_restriction'
        }

        this.setState({
            showRegistrationDateRestriction: showRegistrationDateRestriction,
            restrictedRegistrationDate: restrictedRegistrationDate,
            restrictedRegistrationText: restrictedRegistrationText,
        })
    }

    render() {
        const {formatMessage} = this.context.intl;

        return (
            <div className="pass-selection-description">
                {
                    this.state.showRegistrationDateRestriction &&
                    <div className={"box-message pass-selection-description---time-window-restriction-container"}>
                        <p>
                            <FormattedMessage
                                {...translations[this.state.restrictedRegistrationText]}
                                values={{
                                    date: this.getFormattedDate(this.state.restrictedRegistrationDate)
                                }}
                            />
                        </p>
                    </div>
                }
                <div className={"pass-selection-description--description-container"}>
                    <Image
                        className="pass-selection-description--image"
                        src={this.props.courseDescription.media ? host + this.props.courseDescription.media.path : ''}
                        alt={this.props.courseDescription.media ? host + this.props.courseDescription.media.text_alt : ''}
                        withSpinner={false}
                    />
                    <div className={"pass-selection-description--text-description"}>
                        <HTML contents={this.props.courseDescription.description}/>
                    </div>
                </div>
                <div className={"pass-selection-description--details-container"}>
                    <div className={"pass-selection-description--schedule-container"}>
                        <h3>
                            <FormattedMessage {...translations['schedule']}/>
                        </h3>
                        <p>{this.props.courseDescription.location.local
                            +  (this.props.courseDescription.location.installation
                                ? ' - ' + this.props.courseDescription.location.installation
                                : '' )}</p>
                        <DurationFragment
                            duration={[this.props.courseDescription.start_date, this.props.courseDescription.end_date]}
                            concise={true}
                        />
                        <ScheduleFragment
                            firstToUpper={true}
                            times={this.getSchedule(this.props.courseDescription.start_date, this.props.courseDescription.end_date)}
                            isAllDay={this.props.courseDescription.isAllDay}
                        />
                    </div>
                    <div className={"pass-selection-description--restrictions-container"}>
                        <div className={"pass-selection-description--detail-line"}>
                            <h3 className={"pass-selection-description--detail-title"}>
                                <FormattedMessage
                                    {...translations["restrictions"]}
                                />
                            </h3>
                        </div>
                        <div className={"pass-selection-description--detail-line"}>
                            <p className={"pass-selection-description--detail-title"}>
                                <FormattedMessage
                                    {...translations["place"]}
                                />
                            </p>
                            <p className={
                                "pass-selection-description--detail-text "
                                + (this.state.isGroupFull
                                        ? "pass-selection-description--restriction-status-unavailable"
                                        : "pass-selection-description--restriction-status-available"
                                )
                            }>
                                {this.state.placesText}
                            </p>
                        </div>
                        <div className={"pass-selection-description--detail-line"}>
                            <p className={"pass-selection-description--detail-title"}>
                                <FormattedMessage
                                    {...translations["gender"]}
                                />
                            </p>
                            <p className={"pass-selection-description--detail-text"}>
                               <GenderRestrictions
                                   genders={this.props.courseDescription.gender_restrictions}
                                    targetMemberMessage={""}
                                   forPassModal={true}
                               />
                            </p>
                        </div>
                        <div className={"pass-selection-description--detail-line"}>
                            <p className={"pass-selection-description--detail-title"}>
                                <FormattedMessage
                                    {...translations["age"]}
                                />
                            </p>
                            <p className={"pass-selection-description--detail-text"}>
                                {
                                    this.props.courseDescription.age_restriction &&
                                    <AgeRestriction
                                        min={this.props.courseDescription.age_restriction.min}
                                        max={this.props.courseDescription.age_restriction.max}
                                    />
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

// @ts-ignore
CourseDescription.contextTypes = {
    intl: PropTypes.object,
};


export default CourseDescription;
