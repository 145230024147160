import React from "react";
import PropTypes from "prop-types";
import {defineMessages, FormattedMessage} from "react-intl";
import {poke} from "@app/lib/poke";
import {path} from "@app/lib/routes";
import {Link} from "react-router/es";

const translations = defineMessages({
	"me.address": {id: "qidigo.dashboard.rl24.warnings.me.address", defaultMessage: "Votre adresse doit être configurée dans le profil client afin que les relevés 24 puissent être émis."},
	"me.link.address": {id: "qidigo.dashboard.rl24.warnings.me.link.address", defaultMessage: "Ajouter une adresse"},
	"me.sin": {id: "qidigo.dashboard.rl24.warnings.me.sin", defaultMessage: "Le numéro d'assurance sociale doit être ajouté à votre profil afin que les relevés 24 puissent être émis."},
	"me.guardian": {id: "qidigo.dashboard.rl24.warnings.me.guardian", defaultMessage: "Veuillez vous assurer que tous les parents ou tuteurs respectent les {requirements} au crédit d'impôt pour frais de garde d'enfants."},
	"division.success": {id: "qidigo.dashboard.rl24.warnings.division.success", defaultMessage: "La division a été effectuée avec succès."},
	"unlink.success": {id: "qidigo.dashboard.rl24.warnings.unlike.success", defaultMessage: "La division a été retirée avec succès."},
	"recipient.guardian": {id: "qidigo.dashboard.rl24.warnings.recipient.guardian", defaultMessage: "Veuillez vous assurer que ce parent ou tuteur respecte les {requirements} au crédit d'impôt pour frais de garde d'enfants."},
	"recipient.address": {id: "qidigo.dashboard.rl24.warnings.recipient.address", defaultMessage: "L'adresse du parent ou tuteur doit être saisie afin que les relevés 24 puissent être émis."},
	"recipient.sin": {id: "qidigo.dashboard.rl24.warnings.recipient.sin", defaultMessage: "Le numéro d'assurance sociale doit être saisi afin que les relevés 24 puissent être émis."},
	"information": {id: "qidigo.dashboard.rl24.warnings.information", defaultMessage: "Information"},
	"requirements": {id: "qidigo.dashboard.rl24.warnings.requirements", defaultMessage: "conditions d'admissibilité"}
});

const ErrorBox = ({children}) =>
	<div className="error-message">
		{children}
	</div>
;

const SuccessBox = ({children}) =>
	<div className="dashboard-success">
		<p>
		{children}
		</p>
	</div>
;

const DivisionSuccessWarning = () =>
	<SuccessBox>
		<FormattedMessage {...translations['division.success']} />
	</SuccessBox>
;

const UnlinkSuccessWarning = () =>
	<SuccessBox class="dashboard-success">
		<FormattedMessage {...translations['unlink.success']} />
	</SuccessBox>
;

const AddressWarning = ({who}) =>
	<ErrorBox>
		<p>
			<FormattedMessage {...translations[`${who}.address`]} />
		</p>
		{
			who === "me" &&
				<Link to={path("dashboard.address", {addressID: "new"})}>
					<FormattedMessage {...translations["me.link.address"]} />
				</Link>
		}
	</ErrorBox>
;

AddressWarning.propTypes = {
	who: PropTypes.string.isRequired,
};

const SinWarning = ({who}) =>
	<ErrorBox>
		<p>
			<FormattedMessage {...translations[`${who}.sin`]} />
		</p>
	</ErrorBox>
;

SinWarning.propTypes = {
	who: PropTypes.string.isRequired,
};

const links = {
	requirements:
		<Link target={'blank'} href={"https://www.revenuquebec.ca/fr/citoyens/credits-dimpot/credit-dimpot-pour-frais-de-garde-denfants/conditions-dadmissibilite/"} >
			<FormattedMessage {...translations["requirements"]}/>
		</Link>
};

const GuardianWarning = ({who}) =>
		<p className="dashboard-warning">
			<FormattedMessage {...translations[`${who}.guardian`]} values={links} />
		</p>
;

GuardianWarning.propTypes = {
	who: PropTypes.string.isRequired,
};

GuardianWarning.defaultProps = {
	who: "me",
};


const Boxes = {
	ADDRESS: AddressWarning,
	SIN: SinWarning,
	DIVISION_SUCCESS: DivisionSuccessWarning,
	UNLINK_SUCCESS: UnlinkSuccessWarning,
};

// Component to import for use with a specific list.
const Warnings = ({extraWarnings, who}) => (
	<div>
		{extraWarnings && extraWarnings.length > 0 ?
			extraWarnings.map((key) => {
				const Box = Boxes[key];
				return <Box key={key} who={who}/>;
			}) : null}
		<GuardianWarning who={who}/>
	</div>
);

Warnings.propTypes = {
	extraWarnings: PropTypes.array.isRequired,
	who: PropTypes.string.isRequired,
};

Warnings.defaultProps = {
	who: "me",
};

// Component to import for use with a poked implicit list from a response.
const RL24Warnings = ({$controller: {missing_rl24_informations, successForAction}}) => {
	const extraWarning = [...missing_rl24_informations, ];

	if (successForAction) {
		extraWarning.push(successForAction);
	}

	return <Warnings extraWarnings={extraWarning} />
};

RL24Warnings.propTypes = {
	$controller: PropTypes.object.isRequired,
};

export {Warnings};

export {translations};

export default poke(RL24Warnings, "$controller");
