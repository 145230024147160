import React                 from "react";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

import Button                from "qidigo-components/button.js";

const messages = defineMessages({
	"with": {id: "qidigo.social_login.with", defaultMessage: "À partir de mon compte {name}"},
});


const host = process.env.NODE_ENV === "development" ? "http://localhost:8081" : "https://api.qidigo.com";

const providers = [
	{name: "Facebook", url: host + "/api/v1/authenticate/facebook"},
	{name: "Google",   url: host + "/api/v1/authenticate/google"},
];

const SocialLogin = () =>
	<ul className="social-login">
		{providers.map((provider) => {
			const key = provider.name.toLowerCase();

			return (
				<li key={key} className={`social-login--item social-login--${key}`}>
					<Button
						linkType="a"
						link={provider.url}
						data-provider={key}
						className={`social social--button icon-before provider-${key}`}
					>
						<span>
							<FormattedMessage {...messages["with"]} values={provider} />
						</span>
					</Button>
				</li>
			);
		})}
	</ul>
	;

export default SocialLogin;
