import React                 from "react";
import PropTypes             from "prop-types";
import {
	FormattedMessage,
} from "react-intl";
import COUNTRIES             from "qidigo-data/countries.js";

// FIXME : Utiliser la réponse de l'API au lieu de ça.
import STATES                from "qidigo-data/states.js";

/**
 * Implémente le DOM pour la vue *carte* d'une adresse utilisateur.
 *
 * L'affichage exact (styles) est géré par l'application qui l'utilise et
 * probablement influencé par le container parent.
 */
export const UserAddress = (props) => {
	const {address} = props;
	// Codes pour pays et région.
	/* eslint-disable camelcase */
	const {no_apt, country_id, state} = address;
	/* eslint-enable */

	let tag = null;
	if (props.tag) {
		tag = <span className="address--tag">
			{props.tag}
		</span>;
	}

	return (
		<address className="p-adr h-adr">
			<div className="address--line1">
				<div className="p-street-address">{address.address}
					{/* eslint-disable camelcase */}
					{no_apt && no_apt !== "" ? ", " : ""}
					<span className="address--no-apt">{no_apt}</span>
					{/* eslint-enable */}
					{" "}{tag}
				</div>
			</div>
			<div className="address--line2">
				<span className="p-locality">{address.city["display_name"]}</span>
				{", "}
				<span className="p-postal-code">{address.postal_code}</span>
			</div>
			<div>
				<span className="p-region"><FormattedMessage {...STATES[country_id][state["abbreviation"]]} /></span>
				{", "}
				<span className="p-country-name"><FormattedMessage {...COUNTRIES[country_id]} /></span>
			</div>
		</address>
	);
};

UserAddress.propTypes = {
	// FIXME : shape it
	address: PropTypes.object,
	tag: PropTypes.string,
};

export default UserAddress;
