import env                from "qidigo-env";
import {getLocale}        from "qidigo-i18n";

const GOOGLE_MAP_API_KEY = env.GOOGLE_MAP_API_KEY;

/**
 * Mince couche permettant l'utilisation des API *Google Maps*.
 *
 * #### Au sujet de *Google Maps*:
 *
 * Cette couche sert à faire une abstraction partielle de la manière
 * dont l'API est chargé et appelé, mais autrement, respecte grossièrement
 * l'API de *Google Maps*.
 *
 * Consultez la [documentation officielle](https://developers.google.com/maps/documentation/javascript/reference)
 * pour plus de détails pour l'utilisation des valeurs retournées.
 */
class Geo {
	constructor() {
		this.currentLocale = null;
		this.gmaps   = null;
	}

	/**
	 * Loader compatible avec google-map-react.
	 */
	googleMapLoader(...e) {
		return this.mapsapi();
	}

	/**
	 * Charge au besoin l'API google maps avec la locale active.
	 */
	mapsapi() {
		if (this.currentLocale !== getLocale()) {
			// FIXME : google-maps-api ne fonctionne pas en SSR
			// Il faudrait mieux wrapper.
			const mapsapiLoader = require("google-maps-api"); // eslint-disable-line

			this._mapsapi = mapsapiLoader({
				key:      GOOGLE_MAP_API_KEY,
				language: getLocale(),
			}, ["places"]);
		}

		return this._mapsapi();
	}

	//
	// Google maps
	// ===========
	//

	// Fonction interne pour *wrapper* avec le promise de l'API loader.
	gmapsDo(fn) {
		return this.mapsapi().then((api, ...all) => {
			this.gmaps = api;

			return fn(api, ...all);
		});
	}
	/**
	 * Enregistre un champ comme étant *autocomplété* par l'API de places *Google Maps*.
	 *
	 * Le dernier paramètre, le *dictionnaire* d'événements, est du *syntactic sugar*.
	 *
	 * @param {Element} el Élément `<input>` auquel attacher l'autocomplete.
	 * @param {AutocompleteOptions} opts *[maps:AutocompleteOptions](https://developers.google.com/maps/documentation/javascript/reference#AutocompleteOptions)*
	 * @param {Object} listeners Objet k→v de listeners, où k est la string d'événement et v la fonction.
	 * @return {Promise} Promise avec l'instance d'autocomplete.
	 */
	autocomplete(el, opts, listeners = {}) {
		return this.gmapsDo((api)=>{
			let autocomplete = new api.places.Autocomplete(el, opts);
			for (let [name, fn] of Object.entries(listeners)) {
				autocomplete.addListener(name, ()=>{ return fn(autocomplete); });
			}

			return autocomplete;
		});
	}

	removeEvent(listener) {
		return this.gmapsDo((api)=>{
			api.event.removeListener(listener);

			return null;
		});
	}

	/**
	 * Donne une instance de geocoder via Promise.
	 */
	geocode() {
		return this.gmapsDo((api) => new api.Geocoder());
	}

	/**
	 * À travers une `Promise`, donne une localisation "géocodée" à partir
	 * d'une latitude et d'une longitude.
	 *
	 * @param {Number} lat Latitude
	 * @param {Number} lng Longitude
	 * @param {Integer} precision Précision du géocoding.
	 */
	geocodeLatLng(lat, lng, precision = 1) {
		return this.geocode().then((geocoder) => {
			return new Promise((resolve, reject) => {
				geocoder.geocode({
					location: {
						lat, lng
					}
				},
				(results, status) => {
					if (status === this.gmaps.GeocoderStatus.OK) {
						resolve(results[precision].formatted_address);
					}
					else {
						reject(new Error(`Maps API (GeocoderStatus) status: ${status}`));
					}
				}
				);
			});
		});
	}

	/**
	 * À travers une `Promise`, donne une localisation "géocodée" à partir
	 * d'une adresse textuelle.
	 *
	 * @param {String} address Adresse.
	 */
	geocodeAddress(address) {
		return this.geocode().then((geocoder) => {
			return new Promise((resolve, reject) => {
				geocoder.geocode({address},
					(results, status) => {
						if (status === this.gmaps.GeocoderStatus.OK) {
							resolve({
								lat: results[0]["geometry"]["location"]["lat"](),
								lng: results[0]["geometry"]["location"]["lng"](),
							});
						}
						else {
							reject(new Error(`Maps API (GeocoderStatus) status: ${status}`));
						}
					}
				);
			});
		});
	}
}

export default new Geo();
