import React, {useCallback, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {defineMessages} from "react-intl";
import {age} from "@app/views/shared/user/age";
import AddMemberModal from './add_member_modal';
import noop from 'lodash/noop';
import Select from "react-select";
import {components} from 'react-select';

const translations = defineMessages({
    "template.select": {
        id: "qidigo.shared.family.selector.template.select",
        defaultMessage: "{name} {age}",
        description: "S'affiche comme suit: `Samuel Dionne-Riel (27 ans)`. L'âge est dans le bon format pour la langue."
    },
    "choose": {
        id: "qidigo.shared.family.selector.choose",
        defaultMessage: "Choisir un participant",
    },
    "new_family_member_title": {
        id: "qidigo.dashboard.family_members.new_family_member_title",
        defaultMessage: "Ajouter un nouveau membre",
    },
    "members_selected": {
        id: "qidigo.dashboard.family_members.members_selected",
        defaultMessage: "+ {count} {count, plural, one{membre} other {membres}}  {count, plural, one{sélectionné} other {sélectionnés}} ",
    }
});

const customStyles = {
    menuPortal: base => ({...base, zIndex: 9999}),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#EAEBEDFF',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#27708EFF',
        color: '#EAEBEDFF',
    }),
};


const MoreSelectedBadge = ({items, formatMessage}) => {

    const style = {
        marginLeft: "auto",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        zIndex: 9999
    };

    const title = items.join(", ");
    const count = items.length;
    const label = formatMessage(translations['members_selected'], {count: count});

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};

const MultiValue = ({index, getValue, ...props}, context) => {
    const {formatMessage} = context.intl;
    const isMediumScreen = window.matchMedia('(max-width: 1000px)').matches;

    const maxToShow = isMediumScreen ? 1 : 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} children={getValue}>
            {props.data.label}
        </components.MultiValue>
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} formatMessage={formatMessage}/>
    ) : null;
};

MultiValue.contextTypes = {
    intl: PropTypes.object,
};

const InputOption = ({
                         getStyles,
                         Icon,
                         isDisabled,
                         isFocused,
                         isSelected,
                         children,
                         innerProps,
                         data,
                         ...rest
                     }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let backgroundColor = "transparent";
    if (isFocused) {
        backgroundColor = "#eee";
    }
    if (isActive) {
        backgroundColor = "#B2D4FF";
    }

    const checkboxStyle = {
        marginRight: "0.2rem",
        backgroundColor: '#27708EFF'
    };

    const style = {
        alignItems: "center",
        backgroundColor: backgroundColor,
        color: "inherit",
        display: "flex "
    };

    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            {
                data.value !== 'add' &&
                <input type="checkbox" checked={isSelected} style={checkboxStyle}/>
            }
            {children}
        </components.Option>
    );
};


const FamilyMultiSelector = (props, context) => {
    const {formatMessage} = context.intl;
    const {
        selectedOptions = [],
        options,
        disabled,
        handleChange,
        shouldAllowAddMember = true,
        onNewMemberAdded = noop
    } = props;

    const [hasAddMemberModalOpened, setHasAddMemberModalOpened] = useState(false);

    const closeAddMemberModal = useCallback(
        () => {
            setHasAddMemberModalOpened(false);
        },
        [setHasAddMemberModalOpened],
    );

    const selectOptions = options.map((option) => ({
        value: option["id"],
        label: formatMessage(translations["template.select"], {
            name: option["full_name"],
            age: option["birthday"] ? `(${age(formatMessage, option["birthday"])})` : '',
        }),
    }));

    const handleSelectChange = useCallback(
        (selectedValues) => {
            if (selectedValues.some((value) => value.value === 'add')) {
                setHasAddMemberModalOpened(true);
                return;
            }
            handleChange(selectedValues);

        },
        [handleChange],
    );

    const handleNewMemberAdded = useCallback((data) => {
        onNewMemberAdded(data);
        handleChange([...selectedOptions, {value: data.id, label: data.full_name}]);
        closeAddMemberModal();
    }, [onNewMemberAdded, closeAddMemberModal, selectedOptions, handleChange]);

    const hiddenOptions =
        selectedOptions.length > 3 ? selectedOptions.slice(0, 3) : [];
    const optionsForSelect = selectOptions.filter(
        (x) => !hiddenOptions.includes(x.value)
    );

    return (
        hasAddMemberModalOpened ?
            <AddMemberModal
                onNewMemberAdded={handleNewMemberAdded}
                onClose={closeAddMemberModal}
            />
            :
            <Select
                isMulti
                onChange={handleSelectChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isSearchable={true}
                className="family-multi-selector"
                isDisabled={disabled}
                value={selectedOptions}
                placeholder={formatMessage(translations["choose"])}
                options={
                    shouldAllowAddMember
                        ? [
                            ...optionsForSelect,
                            {
                                value: 'add',
                                label: formatMessage(translations["new_family_member_title"])
                            }
                        ]
                        : selectOptions
                }
                menuPortalTarget={document.body}
                styles={customStyles}
                components={{MultiValue: MultiValue, Option: InputOption}}
            />


    );
};

const WEAK_DATE = PropTypes.oneOfType([PropTypes.object, PropTypes.string]);
const ID_SHAPE = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
const MEMBER_SHAPE = PropTypes.shape({
    id: ID_SHAPE.isRequired,
    full_name: PropTypes.string.isRequired,
    birthday: WEAK_DATE.isRequired,
});

FamilyMultiSelector.propTypes = {
    options: PropTypes.arrayOf(MEMBER_SHAPE).isRequired,
    selectedOptions: PropTypes.arrayOf(PropTypes.object),
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    shouldAllowAddMember: PropTypes.bool,
    onNewMemberAdded: PropTypes.func,
};

FamilyMultiSelector.contextTypes = {
    intl: PropTypes.object,
};

export default FamilyMultiSelector;
