import * as React from "react";
import {Component} from "react";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
import {countries, states, provinces, IBillingAddress, IAddressError, ILoadedStatesCities} from "../../types";
import Select from 'react-select';
import {defineMessages, FormattedMessage} from "react-intl";
import * as PropTypes from "prop-types";
// @ts-ignore
import Input from "qidigo-form/input";
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
// @ts-ignore
import STATES from "qidigo-data/states.js";
// @ts-ignore
import COUNTRIES from "qidigo-data/countries.js";

const translations = defineMessages({
    "no_results": {id: "qidigo.billing.select.no_results", defaultMessage: "Aucun résulat"},
    "tooltip": {id: "qidigo.billing.country.display.us", defaultMessage: "TODO"},
    "new_address": {id: "qidigo.billing.new.address.title", defaultMessage: "Nouvelle adresse de facturation"},
    "street_label": {id: "qidigo.billing.new.address.street.label", defaultMessage: "Numéro civique et rue"},
    "country_label": {id: "qidigo.billing.new.address.country.label", defaultMessage: "Pays"},
    "state_label": {id: "qidigo.billing.new.address.state.label", defaultMessage: "État"},
    "province_label": {id: "qidigo.billing.new.address.province.label", defaultMessage: "Province"},
    "city_label": {id: "qidigo.billing.new.address.city.label", defaultMessage: "Ville"},
    "app_label": {id: "qidigo.billing.new.address.apartment.label", defaultMessage: "#app."},
    "postal_label": {id: "qidigo.billing.new.address.postal_code.label", defaultMessage: "Code postal"},
    "zip_label": {id: "qidigo.billing.new.address.zip_code.label", defaultMessage: "Code ZIP"},
    "save_address": {
        id: "qidigo.billing.new.address.save",
        defaultMessage: "Enregistrer l'adresse pour une utilisation future"
    },
    "required": {id: "qidigo.billing.new.address.required", defaultMessage: "Valeur requise"},
    "max_length": {
        id: "qidigo.billing.new.address.max_length",
        defaultMessage: "La valeur ne peux dépasser {characters} caractères"
    },
});

const CANADA_KEY = 'CA';
const QUEBEC_KEY = 'QC';


const LIMIT = 1500;
const STREET_MAX_LENGTH = 255
const PAYSAFE_STREET_MAX_LENGTH = 50;

const CAN_SAVE_PROVINCE = [
    CANADA_KEY
]

const CAN_LOAD_STATE_CITIES = [
    CANADA_KEY
]

interface IPaymentCustomAddressFormProps {
    selectBillingAddress: (billingAddress: IBillingAddress | null) => void,
    setErrorOnAddress: (errorList: IAddressError[]) => void
    isPaysafeSelected: boolean
}

interface IPaymentCustomAddressFormState {
    customAddress: IBillingAddress,
    isSaveAddressChecked: boolean,
    isSubmitted: boolean,
    isValidAptNo: boolean,
    isValidAddress: boolean,
    isValidCountry: boolean,
    isValidState: boolean,
    isValidCity: boolean,
    isValidPostalCode: boolean,
    errorMessageOfAptNo: string | JSX.Element,
    errorMessageOfAddress: string | JSX.Element,
    errorMessageOfCountry: string | JSX.Element,
    errorMessageOfState: string | JSX.Element,
    errorMessageOfCity: string | JSX.Element,
    errorMessageOfPostalCode: string | JSX.Element,
    selectedCountry: object,
    selectedState: { label: string, value: string },
    selectedCity: object,
    statesToShow: IDisplayStateList[],
    canSaveAddress: boolean,
    cityOptions: any,
    loadedStatesCities: ILoadedStatesCities[],
    errorList: IAddressError[]
}

interface IDisplayStateList {
    code: string,
    name: string,
}

const requiredErrorMessage = <FormattedMessage {...translations["required"]} />;

class PaymentCustomAddressForm extends Component<IPaymentCustomAddressFormProps, IPaymentCustomAddressFormState> {
    constructor(props: IPaymentCustomAddressFormProps) {
        super(props);
        this.state = {
            customAddress: {
                id: null,
                apt_no: '',
                address: '',
                country: CANADA_KEY,
                state: QUEBEC_KEY,
                city: '',
                city_id: null,
                isCustomCity: false,
                willBeSaved: true,
                postal_code: '',
            },
            isSaveAddressChecked: true,
            isSubmitted: false,
            isValidAptNo: false,
            isValidAddress: false,
            isValidCountry: false,
            isValidState: false,
            isValidCity: false,
            isValidPostalCode: false,
            errorMessageOfAptNo: '',
            errorMessageOfAddress: '',
            errorMessageOfCountry: '',
            errorMessageOfState: '',
            errorMessageOfCity: '',
            errorMessageOfPostalCode: '',
            selectedCountry: {label: 'Canada', value: CANADA_KEY},
            selectedState: {label: 'Québec', value: QUEBEC_KEY},
            selectedCity: [],
            statesToShow: provinces,
            canSaveAddress: true,
            cityOptions: [],
            loadedStatesCities: [],
            errorList: []
        };
    }

    componentDidMount() {
        this.loadDefaultCityOptions();
        this.onMountErrorHandling();
    }

    componentDidUpdate(prevProps, prevState: IPaymentCustomAddressFormState) {
        if (prevState.customAddress.state !== this.state.customAddress.state) {
            if (CAN_SAVE_PROVINCE.includes(this.state.customAddress.country)) {
                this.loadDefaultCityOptions();
                this.setState({
                    canSaveAddress: true
                })
            } else {
                this.setState({
                    canSaveAddress: false
                })
            }
            this.setState({
                errorMessageOfCity: '',
                isValidCity: true
            });
        }
    }

    updateProps(billingAddress: IBillingAddress) {
        this.props.selectBillingAddress(billingAddress);
    }

    searchCities = (keyword: string, state: string, country: string, limit: number) => {
        const loadedCityOptions = this.state.loadedStatesCities.find(
            loadedStateCity => loadedStateCity.state === state
        );

        if (CAN_LOAD_STATE_CITIES.includes(country) && loadedCityOptions) {
            this.setState({
                cityOptions: loadedCityOptions.cities
            });

            return;
        }

        const query = `?country=${country}&state=${state}&query=${keyword}&limit=${limit}`;

        return qidigoFetch.get(`cities${query}`)
            .then(response => {
                let values: { value: number, label: string }[] = [];
                response.forEach(element => {
                    values.push({value: element.id, label: element.name})
                });

                const newLoadedStates = this.state.loadedStatesCities;

                let loadedStateCities: ILoadedStatesCities = {
                    'state': state,
                    'cities': values
                };
                newLoadedStates.push(loadedStateCities);

                this.setState({
                    cityOptions: values,
                    loadedStatesCities: newLoadedStates
                });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChangeAddress = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.address = event.currentTarget.value;
        this.validateAddress(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeAptNo = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.apt_no = event.currentTarget.value;
        this.validateAppNo(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeCity = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.city = event.currentTarget.value;
        customAddress.isCustomCity = true;
        this.validateCity(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeCitySelect = (city) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.city = city['label'];
        customAddress.city_id = city['value'];
        customAddress.isCustomCity = false;
        this.validateCity(city['label']);
        this.setState({
            customAddress: customAddress,
            selectedCity: city
        });
        this.updateProps(customAddress);
    };

    handleChangeCountry = (country) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.country = country['value'];
        const isCanadaSelected = customAddress.country == CANADA_KEY;
        customAddress.state = isCanadaSelected
            ? provinces[10].code
            : states[0].code
        customAddress.city = '';
        customAddress.city_id = null;
        const selectedState = isCanadaSelected
            ? {label: provinces[10].name, value: provinces[10].code}
            : {label: states[0].name, value: states[0].code}

        this.validateCountry(country);
        this.setState({
            customAddress: customAddress,
            statesToShow: isCanadaSelected ? provinces : states,
            canSaveAddress: CAN_SAVE_PROVINCE.includes(customAddress.country),
            selectedCountry: country,
            selectedState: selectedState,
            selectedCity: [],
            isSaveAddressChecked: isCanadaSelected,
        });
        this.updateProps(customAddress);
    };

    handleSaveAddressChecked = (event, value: boolean) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.willBeSaved = value;
        this.setState({
                isSaveAddressChecked: value,
                customAddress: customAddress
            }
        )

        this.updateProps(customAddress);
    }

    handleChangeState = (state) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.state = state['value'];
        customAddress.city = '';
        customAddress.city_id = null;

        this.validateState(state['value']);
        this.setState({
            selectedState: state,
            customAddress: customAddress,
            selectedCity: [],
        });
        this.updateProps(customAddress);
        this.validateCity(customAddress.city, false);
    };

    handleChangePostalCode = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.postal_code = event.currentTarget.value;
        this.validatePostalCode(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    isFormValid() {
        return this.state.isValidAddress
            && this.state.isValidAptNo
            && this.state.isValidCountry
            && this.state.isValidState
            && this.state.isValidCity
            && this.state.isValidPostalCode;
    }

    validateAddress(value: string, show: boolean = true) {
        let errorMessage: string | JSX.Element = '';
        let validation = true;

        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        const maxAddressLength = this.props.isPaysafeSelected ? PAYSAFE_STREET_MAX_LENGTH : STREET_MAX_LENGTH

        if (value.length > maxAddressLength) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: maxAddressLength}}/>;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('address_line');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('address_line');
        }

        if (show) {
            this.setState({
                errorMessageOfAddress: errorMessage,
                isValidAddress: validation,
            });
        }
    }

    addErrorFromAddressValidation(errorTag: string) {
        let errors: IAddressError[] = this.state.errorList;
        let alreadyContainsErrorTag: boolean = false;

        errors.forEach(element => {
            if (element.field === errorTag) {
                alreadyContainsErrorTag = true;
                return;
            }
        });

        if (alreadyContainsErrorTag) {
            return;
        }

        errors.push({field: errorTag});
        this.props.setErrorOnAddress(errors);
        this.setState({
            errorList: errors
        });
    }

    removeErrorFromAddressValidation(errorTag: string) {
        const currentErrors: IAddressError[] = this.state.errorList;
        let newErrorList: IAddressError[] = [];

        currentErrors.forEach((element) => {
            if (element.field !== errorTag) {
                newErrorList.push(element);
                return;
            }
        });

        this.props.setErrorOnAddress(newErrorList);
        this.setState({
            errorList: newErrorList
        });
    }

    validateAppNo = (value: string | null) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value !== null && value.length > 10) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: 10}}/>;
            validation = false;
        }

        this.setState({
            errorMessageOfAptNo: errorMessage,
            isValidAptNo: validation
        });
    }

    validateCity = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('city');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('city');
        }

        if (show) {
            this.setState({
                errorMessageOfCity: errorMessage,
                isValidCity: validation
            });
        }
    }

    validateCountry = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('country');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('country');
        }

        if (show) {
            this.setState({
                errorMessageOfCountry: errorMessage,
                isValidCountry: validation
            });
        }

    }

    validateState = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('state');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('state');
        }
        if (show) {
            this.setState({
                errorMessageOfState: errorMessage,
                isValidState: validation
            });
        }
    }

    validatePostalCode = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (value.length > 10) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: 10}}/>;
            validation = false;
        }
        if (!validation) {
            this.addErrorFromAddressValidation('postal_code');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('postal_code');
        }

        if (show) {
            this.setState({
                errorMessageOfPostalCode: errorMessage,
                isValidPostalCode: validation
            });
        }
    }

    showAllErrorMessages() {
        this.validateAppNo(this.state.customAddress.apt_no);
        this.validateAddress(this.state.customAddress.address);
        this.validateCity(this.state.customAddress.city);
        this.validateState(this.state.customAddress.state);
        this.validateCountry(this.state.customAddress.country);
        this.validatePostalCode(this.state.customAddress.postal_code);
    }

    onMountErrorHandling() {
        this.validateAddress(this.state.customAddress.address, false);
        this.validateCity(this.state.customAddress.city, false);
        this.validatePostalCode(this.state.customAddress.postal_code, false);
    }

    handleSubmit() {
        if (!this.isFormValid()) {
            this.showAllErrorMessages();

            return;
        }

        //TODO: validation
        this.setState({
            isSubmitted: true,
        });

        this.props.selectBillingAddress(this.state.customAddress);
    }

    loadDefaultCityOptions() {
        this.searchCities(
            '',
            this.state.customAddress.state,
            this.state.customAddress.country,
            LIMIT)
    }

    render() {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const addressLabel = formatMessage(translations['street_label']);
        const appLabel = formatMessage(translations['app_label']);
        const cityLabel = formatMessage(translations['city_label']);
        const countryLabel = formatMessage(translations['country_label']);
        const stateLabel = this.state.customAddress.country === 'US'
            ? formatMessage(translations['state_label'])
            : formatMessage(translations['province_label'])
        const postalLabel = this.state.customAddress.country === 'US'
            ? formatMessage(translations['zip_label'])
            : formatMessage(translations['postal_label'])

        const customStyle = {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: 32,
                minHeight: 32,
                borderRadius: 3,
                border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none",
                "&:hover": {
                    border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                    boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none"
                }
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: 32,
                padding: '0 6px'
            }),
            input: (provided) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: '30px',
            }),
            menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 9999,
            })
        };

        // @ts-ignore
        const customTheme = (theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary: '#27708EFF',
            },
        })

        const countryOptions = countries.map(country => ({
            label: formatMessage(COUNTRIES[country.code]),
            value: country.code
        }))

        let stateOptions

        if (this.state.customAddress.country === CANADA_KEY) {
            stateOptions = this.state.statesToShow.map(state => ({
                label: formatMessage(STATES[CANADA_KEY][state.code]),
                value: state.code
            }))
        } else {
            stateOptions = this.state.statesToShow.map(state => ({
                label: state.name,
                value: state.code
            }))
        }

        return (
            <form className={"payment-address--custom-form"} onSubmit={(e) => e.preventDefault()}>
                <div className={"payment-address--custom-form-container"}>
                    <div className="payment-address--custom-form-title">
                        <FormattedMessage {...translations["new_address"]} />
                    </div>
                    <div className={"payment-address--custom-form-address-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-address-input"}
                                type={"text"}
                                label={addressLabel}
                                value={this.state.customAddress.address}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangeAddress}
                                onBlur={this.handleChangeAddress}
                                errorMessage={this.state.errorMessageOfAddress}
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-app-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-app-input"}
                                type={"text"}
                                name={"app"}
                                label={appLabel}
                                value={this.state.customAddress.apt_no
                                !== null ? this.state.customAddress.apt_no
                                    : ''}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangeAptNo}
                                errorMessage={this.state.errorMessageOfAptNo}
                                maxLength={10} //Paysafe max
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-country-input-container"}>
                        <div>
                            <Select
                                placeholder={countryLabel}
                                required
                                styles={customStyle}
                                theme={customTheme}
                                value={this.state.selectedCountry}
                                onChange={this.handleChangeCountry}
                                isDisabled={this.state.isSubmitted}
                                options={countryOptions}
                                menuPortalTarget={document.body}
                                noOptionsMessage={() => formatMessage(translations['no_results'])}
                            ></Select>
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-state-input-container"}>
                        <div>
                            <Select
                                placeholder={stateLabel}
                                required
                                styles={customStyle}
                                theme={customTheme}
                                value={this.state.selectedState}
                                onChange={this.handleChangeState}
                                isDisabled={this.state.isSubmitted}
                                options={stateOptions}
                                menuPortalTarget={document.body}
                                noOptionsMessage={() => formatMessage(translations['no_results'])}
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-city-input-container"}>
                        <div>
                            {
                                this.state.canSaveAddress ?
                                    <Select
                                        styles={customStyle}
                                        theme={customTheme}
                                        placeholder={cityLabel}
                                        required
                                        key={"city-select-list"}
                                        value={this.state.selectedCity}
                                        options={this.state.cityOptions}
                                        onChange={this.handleChangeCitySelect}
                                        menuPortalTarget={document.body}
                                        noOptionsMessage={() => formatMessage(translations['no_results'])}
                                    /> :
                                    <Input
                                        id={"input--select-city"}
                                        type={"text"}
                                        label={cityLabel}
                                        value={this.state.customAddress.city}
                                        disabled={this.state.isSubmitted}
                                        onChange={this.handleChangeCity}
                                        errorMessage={this.state.errorMessageOfCity}
                                    />
                            }
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-postal-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-postal-input"}
                                type={"text"}
                                name={"postal-code"}
                                label={postalLabel}
                                value={this.state.customAddress.postal_code}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangePostalCode}
                                maxLength={10}
                            />
                        </div>
                    </div>
                </div>
                {
                    this.state.canSaveAddress &&
                    <div className={"payment-address--custom-form-save-address-container"}>
                        <Checkbox
                            value={this.state.isSaveAddressChecked}
                            id={"save-custom-address"}
                            onChange={(e, v) => this.handleSaveAddressChecked(e, v)}
                        />
                        <label htmlFor={"save-custom-address"}>
                            <FormattedMessage {...translations["save_address"]} />
                        </label>
                    </div>
                }
            </form>
        )
    }
}

// @ts-ignore
PaymentCustomAddressForm.contextTypes = {
    intl: PropTypes.object,
};

export default PaymentCustomAddressForm;
