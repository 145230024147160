import * as React from "react";
import {Component} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import SocialLogin from "@app/components/social_login";
import {ISession} from "../../types";

const translations = defineMessages({
    "with": {
        id: "qidigo.social_login.with",
        defaultMessage: "À partir de mon compte {name}"
    },
})

const host = process.env.NODE_ENV === "development" ? "http://localhost:8081" : "https://api.qidigo.com";

const providers = [
    {name: "Facebook", url: `${host}/api/v1/authenticate/facebook?returnUrl=${host}/facebook/return`},
    {name: "Google", url: `${host}/api/v1/authenticate/google?returnUrl=${host}/google/return`},
];

interface ISocialLoginProps {
    session: ISession,
}

interface ISocialLoginState {
}

class SocialLogin extends Component<ISocialLoginProps, ISocialLoginState> {
    constructor(props: ISocialLoginProps) {
        super(props);
    }

    navigateToProvider = (url) => {
        localStorage.setItem('lastSelectedSession', JSON.stringify(this.props.session));
        window.location.href =  url;
    }

    render() {
        return (
            <div className={"pass-selection-login--social-login social-login"}>
                {providers.map((provider, index) => {
                    const key = provider.name.toLowerCase();
                    return (
                        <div className={`pass-selection-login--social-button-container`}>
                            <Button
                                linkType="a"
                                onClick={() => this.navigateToProvider(provider.url)}
                                data-provider={key}
                                className={`icon-before pass-selection-login--social-button`}
                            >
                                <div className={`pass-selection-login--social-button-icon-${key}`}></div>
                                <FormattedMessage {...translations["with"]} values={provider}/>
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default SocialLogin;
