import {defineMessages}      from "react-intl";

const STATES = {
	CA: defineMessages({
		AB: {id: "DATA.states.CA.AB", defaultMessage: "Alberta"},
		BC: {id: "DATA.states.CA.BC", defaultMessage: "Colombie-Britannique"},
		MB: {id: "DATA.states.CA.MB", defaultMessage: "Manitoba"},
		NB: {id: "DATA.states.CA.NB", defaultMessage: "Nouveau-Brunswick"},
		NL: {id: "DATA.states.CA.NL", defaultMessage: "Terre-Neuve et Labrador"},
		NS: {id: "DATA.states.CA.NS", defaultMessage: "Nouvelle-Écosse"},
		NT: {id: "DATA.states.CA.NT", defaultMessage: "Territoires du Nord-Ouest"},
		NU: {id: "DATA.states.CA.NU", defaultMessage: "Nunavut"},
		ON: {id: "DATA.states.CA.ON", defaultMessage: "Ontario"},
		PE: {id: "DATA.states.CA.PE", defaultMessage: "Île-du-Prince-Édouard"},
		QC: {id: "DATA.states.CA.QC", defaultMessage: "Québec"},
		SK: {id: "DATA.states.CA.SK", defaultMessage: "Saskatchewan"},
		YT: {id: "DATA.states.CA.YT", defaultMessage: "Yukon"},
	}),
	//	US: defineMessages({
	//		AK: {id: "DATA.states.US.AK", defaultMessage: "Alaska"},
	//		AL: {id: "DATA.states.US.AL", defaultMessage: "Alabama"},
	//		AR: {id: "DATA.states.US.AR", defaultMessage: "Arkansas"},
	//		AZ: {id: "DATA.states.US.AZ", defaultMessage: "Arizona"},
	//		CA: {id: "DATA.states.US.CA", defaultMessage: "Californie"},
	//		CO: {id: "DATA.states.US.CO", defaultMessage: "Colorado"},
	//		CT: {id: "DATA.states.US.CT", defaultMessage: "Connecticut"},
	//		DE: {id: "DATA.states.US.DE", defaultMessage: "Delaware"},
	//		FL: {id: "DATA.states.US.FL", defaultMessage: "Floride"},
	//		GA: {id: "DATA.states.US.GA", defaultMessage: "Géorgie"},
	//		HI: {id: "DATA.states.US.HI", defaultMessage: "Hawaï"},
	//		IA: {id: "DATA.states.US.IA", defaultMessage: "Iowa"},
	//		ID: {id: "DATA.states.US.ID", defaultMessage: "Idaho"},
	//		IL: {id: "DATA.states.US.IL", defaultMessage: "Illinois"},
	//		IN: {id: "DATA.states.US.IN", defaultMessage: "Indiana"},
	//		KS: {id: "DATA.states.US.KS", defaultMessage: "Kansas"},
	//		KY: {id: "DATA.states.US.KY", defaultMessage: "Kentucky"},
	//		LA: {id: "DATA.states.US.LA", defaultMessage: "Louisiane"},
	//		MA: {id: "DATA.states.US.MA", defaultMessage: "Massachusetts"},
	//		MD: {id: "DATA.states.US.MD", defaultMessage: "Maryland"},
	//		ME: {id: "DATA.states.US.ME", defaultMessage: "Maine"},
	//		MI: {id: "DATA.states.US.MI", defaultMessage: "Michigan"},
	//		MN: {id: "DATA.states.US.MN", defaultMessage: "Minnesota"},
	//		MO: {id: "DATA.states.US.MO", defaultMessage: "Missouri"},
	//		MS: {id: "DATA.states.US.MS", defaultMessage: "Mississippi"},
	//		MT: {id: "DATA.states.US.MT", defaultMessage: "Montana"},
	//		NC: {id: "DATA.states.US.NC", defaultMessage: "Caroline du Nord"},
	//		ND: {id: "DATA.states.US.ND", defaultMessage: "Dakota du Nord"},
	//		NE: {id: "DATA.states.US.NE", defaultMessage: "Nebraska"},
	//		NH: {id: "DATA.states.US.NH", defaultMessage: "New Hampshire"},
	//		NJ: {id: "DATA.states.US.NJ", defaultMessage: "New Jersey"},
	//		NM: {id: "DATA.states.US.NM", defaultMessage: "Nouveau-Mexique"},
	//		NV: {id: "DATA.states.US.NV", defaultMessage: "Nevada"},
	//		NY: {id: "DATA.states.US.NY", defaultMessage: "New York"},
	//		OH: {id: "DATA.states.US.OH", defaultMessage: "Ohio"},
	//		OK: {id: "DATA.states.US.OK", defaultMessage: "Oklahoma"},
	//		OR: {id: "DATA.states.US.OR", defaultMessage: "Oregon"},
	//		PA: {id: "DATA.states.US.PA", defaultMessage: "Pennsylvanie"},
	//		RI: {id: "DATA.states.US.RI", defaultMessage: "Rhode Island"},
	//		SC: {id: "DATA.states.US.SC", defaultMessage: "Caroline du Sud"},
	//		SD: {id: "DATA.states.US.SD", defaultMessage: "Dakota du Sud"},
	//		TN: {id: "DATA.states.US.TN", defaultMessage: "Tennessee"},
	//		TX: {id: "DATA.states.US.TX", defaultMessage: "Texas"},
	//		UT: {id: "DATA.states.US.UT", defaultMessage: "Utah"},
	//		VA: {id: "DATA.states.US.VA", defaultMessage: "Virginie"},
	//		VT: {id: "DATA.states.US.VT", defaultMessage: "Vermont"},
	//		WA: {id: "DATA.states.US.WA", defaultMessage: "Washington"},
	//		WI: {id: "DATA.states.US.WI", defaultMessage: "Wisconsin"},
	//		WV: {id: "DATA.states.US.WV", defaultMessage: "Virginie-Occidentale"},
	//		WY: {id: "DATA.states.US.WY", defaultMessage: "Wyoming"},
	//	}),
};

export default STATES;

